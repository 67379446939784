import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Type from '../utils/type';
import { timeAgo } from '../utils/timeAgo';
import StatusDiv from "../utils/status";
// toast lib ==>
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";
import { Dropdown } from "primereact/dropdown";
// icons ==>
import 'primeicons/primeicons.css';
// tabs ==>
import { TabView, TabPanel } from 'primereact/tabview';
// Dialog ==>
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import CryptoJS from 'crypto-js';

import apiRequest from '../utils/apiHelper';



const Landing = () => {
  const navigate = useNavigate();
  const Auth_Cookie = Cookies.get("avaAuth");
  const user_role = Cookies.get("theme");
  const [Role, setRole] = useState("user");
  const [isLoading, setIsLoading] = useState(true);
  const [isValidCookie, setIsValidCookie] = useState(false);
  const [USD_Rate, setUSD_Rate] = useState([]);
  const [USDT_Rate, setUSDT_Rate] = useState([]);
  const [EUR_Rate, setEUR_Rate] = useState([]);
  const [GBP_Rate, setGBP_Rate] = useState([]);
  const toast = useRef(null);
  const [payments, setPayment] = useState([]);
  const [trades, setTrades] = useState([]);
  const [InnerLoader, setInnerLoader] = useState(false);
  const [balance, setBalance] = useState(null);
  const [miniLoader, setMiniLoader] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [users, setUsers] = useState([]);

  // add order ==>
  const [amountDeposit, setAmountDeposit] = useState("");
  const [amountWithdraw, setAmountWithdraw] = useState("");
  const [type, setType] = useState("");
  const [rates, setRate] = useState([]);
  const [wallets, setWallets] = useState("");

  // calc order ==>
  const [finalDeposit, setFinalDeposit] = useState("");
  const [finalWithdraw, setFinalWithdraw] = useState("");

  // dropdown ==>
  const [selectedDepositRate, setselectedDepositRate] = useState(null);
  const [selectedWithdrawRate, setselectedWithdrawRate] = useState(null);

  // Admin dialoge ==>
  const [visibleOrderData, setVisibleOrderData] = useState(false);
  const [userPaymentData, setUserPaymentData] = useState([]);
  const [userOrderData, setUserOrderData] = useState([]);
  const [visibleRejectReason, setVisibleRejectReason] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const [ContentLoading, setContentLoading] = useState(true);


  const decrypt = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {

    if (!Auth_Cookie) {
      navigate("/login");
      return; // Exit early if no cookie is present
    }

    if (decrypt(user_role) == 'user') {
      setRole('user')
    } else if (decrypt(user_role) == 'admin') {
      fetchTickets();
      fetchUsers();
      setRole('admin')
    } else {
      setRole('user')
    }
  }, [Role, setRole]);


  const OrderAction = async (OrderId, Status, Type, Reason) => {
    try {
      setInnerLoader(true);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/trades/trade.php?` +
        `OrderId=${encodeURIComponent(OrderId)}&` +
        `Status=${encodeURIComponent(parseInt(Status))}&` +
        `Type=${Type}&` +
        `Reason=${Reason}`
        ,
        {
          method: "PUT",
          headers: {
            'Authorization': `Bearer ${Auth_Cookie}`,
          },
          redirect: "follow",
        },
      );

      const data = await response.json();
      if (data.status === 200) {
        window.location.reload();
      } else {
        alert(data.message)
        setInnerLoader(false);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
      } else {
        console.log('An unexpected error occurred');
      }
    }
  };

  const footerTradeDialoge = (
    <div className="grid grid-cols-2 gap-5" >
      <Button label="تایید پرداخت" type="submit" onClick={() => OrderAction(userOrderData['id'], 200, userOrderData['Type'])} icon="pi pi-check" autoFocus />
      <Button label="رد پرداخت" icon="pi pi-times" onClick={() => setVisibleRejectReason(true)} className="p-button-text" />
    </div>
  );

  const getPayment = async (OrderId) => {

    setInnerLoader(true);

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/public/api/v1/payments/payment.php?order_id=${OrderId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          'Authorization': `Bearer ${Auth_Cookie}`,
        },
        redirect: "follow",
      },
    );

    const data = await response.json()
    if (data.status === 200) {
      setUserPaymentData(data.message[0])
      setInnerLoader(false);
    } else {
      alert(data.message)
    }
  };

  // get order data for payment details modal ==>
  const openOrderData = (order) => {
    // setUserPaymentData(payment);
    setUserOrderData(order);
    getPayment(order['id'])
    setVisibleOrderData(true)
  };

  const fetchRates = async () => {
    try {
      // Mocking API call to validate cookie, replace with actual API call
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/v1/rates/rate.php`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth_Cookie}`,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        if (data.status == 200) {
          setIsValidCookie(true);
          setRate(data.message);
          //EUR_Rate(data)
          // Define a map for currency types and setter functions
          const currencySetters = {
            USD: setUSD_Rate,
            USDT: setUSDT_Rate,
            EUR: setEUR_Rate,
            GBP: setGBP_Rate,
            // Add more currency types if needed
          };
          // Iterate over the array and set rates using the map
          data.message.forEach((item) => {
            const setter = currencySetters[item.Currency];
            if (setter) {
              setter({ "SellRate": item.SellRate, "BuyRate": item.BuyRate });
            }
          });
        } else {
          navigate("/login");
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    } finally {
      setIsLoading(false); // Always set loading to false when done
    }
  };

  const fetchPayments = async (retries = 3) => {
    try {
      // Mocking API call to validate cookie, replace with actual API call
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/v1/payments/payment.php`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth_Cookie}`,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        if (data.status == 200) {
          setPayment(data.message.slice(0, 10));
        } else if (response.status === 503) {
          retries++;
          await new Promise(resolve => setTimeout(resolve, 1000));
        } else {
          if (data.status == 403) {
            Cookies.remove("avaAuth");
            navigate("/login");
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: data.message,
              life: 5000,
            });
          }
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    } finally {
      setIsLoading(false); // Always set loading to false when done
    }
  };

  const fetchTrades = async () => {
    try {
      // Mocking API call to validate cookie, replace with actual API call

      // const checker =  decrypt(user_role) === 'admin'; // Check if the auth cookie equals 'admin'
      const tradeUrl = `${process.env.REACT_APP_BASE_URL}/public/api/v1/trades/trade.php`;
      // const finalUrl = checker ? `${orderUrl}?pending` : orderUrl;

      const response = await fetch(tradeUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth_Cookie}`,
        },
      },
      );

      if (response.ok) {
        const data = await response.json();
        if (data.status == 200) {
          setTrades(data.message.slice(0, 6));
          setContentLoading(false);
        } else {
          if (data.status == 403) {
            Cookies.remove("avaAuth");
            navigate("/login");
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: data.message,
              life: 5000,
            });
          }
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    } finally {
      setIsLoading(false); // Always set loading to false when done
    }
  };

  const fetchWallets = async (retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        // Mocking API call to validate cookie, replace with actual API call
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/public/api/v1/wallets/wallet.php`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth_Cookie}`,
            },
          },
        );

        if (response.ok) {
          const data = await response.json();
          if (data.status == 200) {
            setWallets(data.message);
          } else {
            if (data.status == 403) {
              Cookies.remove("avaAuth");
              navigate("/login");
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: data.message,
                life: 5000,
              });
            }
          }
        } else if (response.status === 503) {
          retries++;
          await new Promise(resolve => setTimeout(resolve, 1000));
        } else {
          navigate("/login");
        }
      } catch (error) {
        navigate("/login");
      } finally {
        setIsLoading(false); // Always set loading to false when done
      }
    }
  };

  const fetchBalance = async () => {
    try {
      // Mocking API call to validate cookie, replace with actual API call
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/v1/users/user.php`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth_Cookie}`,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        if (data.status == 200) {
          setBalance(data.message[0].Balance)
        } else {
          alert(data.status)
        }
      } else {
        console.log(response)
      }
    } catch (error) {
      console.log(error)
    }
  };

  const fetchTickets = async (retries = 3) => {
    try {
      // Mocking API call to validate cookie, replace with actual API call
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/v1/tickets/ticket.php`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth_Cookie}`,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        if (data.status == 200) {
          setTickets(data.message);
          setIsValidCookie(true);
          setIsLoading(false);
          setContentLoading(false);
        } else if (response.status === 503) {
          retries++;
          await new Promise(resolve => setTimeout(resolve, 1000));
        } else {
          if (data.status == 403) {
            Cookies.remove("avaAuth");
            navigate("/login");
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: data.message,
              life: 5000,
            });
          }
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      navigate("/login");
    } finally {
      setIsLoading(false);
    }
  };


  const fetchUsers = async () => {
    const response = await apiRequest("/public/api/v1/users/user.php", Auth_Cookie);

    if (response.success) {
      setUsers(response.data.message.slice(0, 6));
    } else {
      if (response.error === "Invalid token") {
        console.error("Token is invalid, redirecting to login.");
        navigate("/login");
      } else {
        if (toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: response.error,
            life: 5000,
          });
        }
      }
    }
  };




  useEffect(() => {
    const Identify = Cookies.get("Identify");

    fetchRates();
    fetchBalance();
    fetchPayments();
    fetchTrades();
    fetchWallets();
  }, []);

  useEffect(() => {
    if (amountDeposit && selectedDepositRate) {
      setFinalDeposit(parseFloat(selectedDepositRate["SellRate"]) * parseInt(amountDeposit));
    }
  }, [amountDeposit, selectedDepositRate]);

  useEffect(() => {
    if (amountWithdraw && selectedWithdrawRate) {
      setFinalWithdraw(parseFloat(selectedWithdrawRate["BuyRate"]) * parseInt(amountWithdraw));
    }
  }, [amountWithdraw, selectedWithdrawRate]);


  const addDeposit = async (event) => {
    event.preventDefault();

    if (selectedDepositRate != null) {
      setMiniLoader(true)
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/v1/trades/trade.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'Authorization': `Bearer ${Auth_Cookie}`,
          },
          body: new URLSearchParams({
            Amount: amountDeposit,
            BaseCurrency: selectedDepositRate['Currency'],
            Operation: 'BalanceToWallet'
          }),
          redirect: "follow",
        },
      );

      const data = await response.json()

      if (data.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "موفق",
          detail: "خرید ارز با موفقیت انجام شد",
          life: 7000,
        });
        // refresh data ==>
        fetchTrades();
        fetchBalance();
        fetchWallets();
        setMiniLoader(false);
        setVisibleDeposit(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "خطار",
          detail: data.message,
          life: 7000,
        });
        setMiniLoader(false);
        setVisibleDeposit(false);
      }
    } else {
      alert('مقدار ارز مشخص نیست')
      setIsLoading(false);
    }
  };

  const addWithdraw = async (event) => {
    event.preventDefault();

    if (selectedWithdrawRate != null) {
      setMiniLoader(true)

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/public/api/v1/trades/trade.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'Authorization': `Bearer ${Auth_Cookie}`,
          },
          body: new URLSearchParams({
            Amount: amountWithdraw,
            BaseCurrency: selectedWithdrawRate['Currency'],
            Operation: 'WalletToBalance'
          }),
          redirect: "follow",
        },
      );

      const data = await response.json();

      if (data.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "موفق",
          detail: "فروش ارز با موفقیت انجام شد",
          life: 7000,
        });
        // refresh data ==>
        fetchTrades();
        fetchBalance();
        fetchWallets();
        setMiniLoader(false);
        setVisibleDeposit(false);

      } else {
        toast.current.show({
          severity: "error",
          summary: "خطار",
          detail: data.message,
          life: 7000,
        });
        setMiniLoader(false);
        setVisibleDeposit(false);
      }
    } else {
      alert('مقدار ارز مشخص نیست')
      setIsLoading(false);
    }
  };

  const openDepositDialog = async (event) => {
    event.preventDefault();

    if (parseInt(finalDeposit) > 0) {
      setVisibleDeposit(true)
    } else {
      toast.current.show({
        severity: "error",
        summary: "خطا",
        detail: "مقدار واریز موردنظر را وارد کنید",
        life: 5000,
      });
    }
  };

  const openWithdrawDialog = async (event) => {
    event.preventDefault();

    if (parseInt(finalWithdraw) > 0) {
      setVisibleWithdraw(true)
    } else {
      toast.current.show({
        severity: "error",
        summary: "خطا",
        detail: "مقدار برداشت موردنظر را وارد کنید",
        life: 5000,
      });
    }
  };

  // Dialog ==>
  // Deposit
  const [visibleDeposit, setVisibleDeposit] = useState(false);
  const footerContentDeposit = (
    <>
      {miniLoader ? (
        <div className="mini_loader pt-0 mr-4">
          <div className="loader_small"></div>
        </div>
      ) : (

        <div className="grid grid-cols-2 gap-5" >
          <Button label="تایید" type="submit" icon="pi pi-check" onClick={addDeposit} autoFocus />
          <Button label="انصراف" icon="pi pi-times" onClick={() => setVisibleDeposit(false)} className="p-button-text" />
        </div>

      )}
    </>
  );
  // Withdraw
  const [visibleWithdraw, setVisibleWithdraw] = useState(false);
  const footerContentWithdraw = (
    <>
      {miniLoader ? (
        <div className="mini_loader pt-0 mr-4">
          <div className="loader_small"></div>
        </div>
      ) : (

        <div className="grid grid-cols-2 gap-5" >
          <Button label="تایید" type="submit" icon="pi pi-check" onClick={addWithdraw} autoFocus />
          <Button label="انصراف" icon="pi pi-times" onClick={() => setVisibleWithdraw(false)} className="p-button-text" />
        </div>

      )}
    </>
  );

  return (
    <>
      {InnerLoader != "" ? (
        <div className="loading_wrapper">
          <span className="loader"></span>
        </div>
      ) : (
        <></>
      )}
      <Toast ref={toast} />
      <div className="lg:flex">
        <Sidebar />
        <div className="mainbar">
          <Navbar />
          <div className="main">
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 lg:gap-5">

              <div className="card p-4 lg:p-5">
                <div className="lg:block items-center">
                  <div className="card_heading_meta flex items-center">
                    <div className="card_icon black_bg">
                      <svg
                        fill="#000000"
                        width={35}
                        height={35}
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16-7.163 16-16 16zm1.922-18.207v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117zm0 3.59v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657z"
                        />
                      </svg>
                    </div>


                    {Role === "admin" ? (
                      <><span className="rate_title" >نرخ های تتر
                        <b className="admin_unit">USDT</b>
                      </span></>
                    ) : (
                      wallets.length > 0 ? (
                        <div className="pr-4">
                          <span className="balance_name">موجودی تتر</span>
                          <div className="flex items-center">
                            <span className="balance_amount number ml-2" > {wallets[0]['USDT'] != null ? (parseFloat(wallets[0]['USDT']).toLocaleString()) : (0)}</span>
                            <span className="balance_currency">USDT</span>
                          </div>
                        </div>
                      ) : (
                        <div className="mini_loader">
                          <div className="loader_small"></div>
                        </div>
                      )
                    )}


                  </div>

                  <div className="lg:flex items-center rate_cols" >
                    <div className="counter text-center lg:flex items-center justify-center w-full py-2 lg:w-3/6 lg:p-0">
                      <i className="pi pi-arrow-circle-down lg:ml-3" style={{ fontSize: '1rem' }}></i>
                      <div>
                        <b className="main_value">
                          {parseFloat(USDT_Rate["SellRate"]).toLocaleString()}
                        </b>
                        <small>خرید ازما</small>
                      </div>
                    </div>
                    <span className="spacer"></span>
                    <div className="counter text-center lg:flex items-center justify-center w-full py-2 lg:w-3/6 lg:p-0">
                      <i className="pi pi-arrow-circle-up lg:ml-3" style={{ fontSize: '1rem' }}></i>
                      <div>
                        <b className="main_value">
                          {parseFloat(USDT_Rate["BuyRate"]).toLocaleString()}
                        </b>
                        <small>فروش به ما</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card p-4 lg:p-5">
                <div className="lg:block items-center">

                  <div className="card_heading_meta flex items-center">
                    <div className="card_icon green_bg">
                      <svg
                        width={35}
                        height={35}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V6.31673C9.61957 6.60867 8.25 7.83361 8.25 9.5C8.25 11.4172 10.0628 12.75 12 12.75C13.3765 12.75 14.25 13.6557 14.25 14.5C14.25 15.3443 13.3765 16.25 12 16.25C10.6235 16.25 9.75 15.3443 9.75 14.5C9.75 14.0858 9.41421 13.75 9 13.75C8.58579 13.75 8.25 14.0858 8.25 14.5C8.25 16.1664 9.61957 17.3913 11.25 17.6833V18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18V17.6833C14.3804 17.3913 15.75 16.1664 15.75 14.5C15.75 12.5828 13.9372 11.25 12 11.25C10.6235 11.25 9.75 10.3443 9.75 9.5C9.75 8.65573 10.6235 7.75 12 7.75C13.3765 7.75 14.25 8.65573 14.25 9.5C14.25 9.91421 14.5858 10.25 15 10.25C15.4142 10.25 15.75 9.91421 15.75 9.5C15.75 7.83361 14.3804 6.60867 12.75 6.31673V6Z"
                          fill="#1C274C"
                        />
                      </svg>
                    </div>


                    {Role === "admin" ? (
                      <><span className="rate_title" >نرخ های دلار
                        <b className="admin_unit">USD</b>
                      </span></>
                    ) : (
                      wallets.length > 0 ? (
                        <div className="pr-4">
                          <span className="balance_name">موجودی دلار</span>
                          <div className="flex items-center">
                            <span className="balance_amount number ml-2" > {wallets[0]['USD'] != null ? (parseFloat(wallets[0]['USD']).toLocaleString()) : (0)}</span>
                            <span className="balance_currency">USD</span>
                          </div>
                        </div>
                      ) : (
                        <div className="mini_loader">
                          <div className="loader_small"></div>
                        </div>
                      )
                    )}

                  </div>

                  <div className="lg:flex items-center rate_cols" >
                    <div className="counter text-center lg:flex items-center justify-center w-full py-2 lg:w-3/6 lg:p-0">
                      <i className="pi pi-arrow-circle-down lg:ml-3" style={{ fontSize: '1rem' }}></i>
                      <div>
                        <b className="main_value">
                          {parseFloat(USD_Rate["SellRate"]).toLocaleString()}
                        </b>
                        <small>خرید ازما</small>
                      </div>
                    </div>
                    <span className="spacer"></span>
                    <div className="counter text-center lg:flex items-center justify-center w-full py-2 lg:w-3/6 lg:p-0">
                      <i className="pi pi-arrow-circle-up lg:ml-3" style={{ fontSize: '1rem' }}></i>
                      <div>
                        <b className="main_value">
                          {parseFloat(USD_Rate["BuyRate"]).toLocaleString()}
                        </b>
                        <small>فروش به ما</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card p-4 lg:p-5">
                <div className="lg:block items-center">


                  <div className="card_heading_meta flex items-center">
                    <div className="card_icon blue_bg">
                      <svg
                        fill="#000000"
                        width={35}
                        height={35}
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon"
                      >
                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm63.5 375.8c4.4 0 8 3.6 8 8V475c0 4.4-3.6 8-8 8h-136c-.3 4.4-.3 9.1-.3 13.8v36h136.2c4.4 0 8 3.6 8 8V568c0 4.4-3.6 8-8 8H444.9c15.3 62 61.3 98.6 129.8 98.6 19.9 0 37.1-1.2 51.8-4.1 4.9-1 9.5 2.8 9.5 7.8v42.8c0 3.8-2.7 7-6.4 7.8-15.9 3.4-34.3 5.1-55.3 5.1-109.8 0-183-58.8-200.2-158H344c-4.4 0-8-3.6-8-8v-27.2c0-4.4 3.6-8 8-8h26.1v-36.9c0-4.4 0-8.8.3-12.8H344c-4.4 0-8-3.6-8-8v-27.2c0-4.4 3.6-8 8-8h31.7c19.7-94.2 92-149.9 198.6-149.9 20.9 0 39.4 1.9 55.3 5.4 3.7.8 6.3 4 6.3 7.8V346h.1c0 5.1-4.6 8.8-9.6 7.8-14.7-2.9-31.8-4.4-51.7-4.4-65.4 0-110.4 33.5-127.6 90.4h128.4z" />
                      </svg>
                    </div>


                    {Role === "admin" ? (
                      <><span className="rate_title" >نرخ های یورو
                        <b className="admin_unit">EUR</b>
                      </span></>
                    ) : (
                      wallets.length > 0 ? (
                        <div className="pr-4">
                          <span className="balance_name">موجودی یورو</span>
                          <div className="flex items-center">
                            <span className="balance_amount number ml-2">
                              {wallets[0]['EUR'] != null ? parseFloat(wallets[0]['EUR']).toLocaleString() : 0}
                            </span>
                            <span className="balance_currency">EUR</span>
                          </div>
                        </div>
                      ) : (
                        <div className="mini_loader">
                          <div className="loader_small"></div>
                        </div>
                      )
                    )}


                  </div>

                  <div className="lg:flex items-center rate_cols" >
                    <div className="counter text-center lg:flex items-center justify-center w-full py-2 lg:w-3/6 lg:p-0">
                      <i className="pi pi-arrow-circle-down lg:ml-3" style={{ fontSize: '1rem' }}></i>
                      <div>
                        <b className="main_value">
                          {parseFloat(EUR_Rate["SellRate"]).toLocaleString()}
                        </b>
                        <small>خرید ازما</small>
                      </div>
                    </div>
                    <span className="spacer"></span>
                    <div className="counter text-center lg:flex items-center justify-center w-full py-2 lg:w-3/6 lg:p-0">
                      <i className="pi pi-arrow-circle-up lg:ml-3" style={{ fontSize: '1rem' }}></i>
                      <div>
                        <b className="main_value">
                          {parseFloat(EUR_Rate["BuyRate"]).toLocaleString()}
                        </b>
                        <small>فروش به ما</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="card p-4 lg:p-5">
                <div className="lg:block items-center">


                  <div className="card_heading_meta flex items-center">
                    <div className="card_icon orange_bg">


                      <svg width={35} height={35} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17.5" cy="17.5" r="17.5" fill="black" />
                        <g clipPath="url(#clip0_636_1524)">
                          <path d="M14.2844 21.6013V17.3242H21.9826V15.5585H14.2844V11.5904C14.2844 9.46314 16.0397 7.76564 18.1711 7.76564C20.3023 7.76564 22.0576 9.48482 22.0576 11.6742H23.8233C23.8232 8.49604 21.2759 6 18.1711 6C15.0662 6 12.5187 8.48956 12.5187 11.5904V15.5586H11.1769V17.3242H12.5187V21.6014C12.5187 23.5977 11.6712 24.6095 11.1769 25.0367V26.8646L11.2431 27H23.7482V25.2343H13.3745C13.8942 24.3868 14.2844 23.2146 14.2844 21.6013Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_636_1524">
                            <rect width="21" height="21" fill="white" transform="translate(7 6)" />
                          </clipPath>
                        </defs>
                      </svg>

                    </div>


                    {Role === "admin" ? (
                      <><span className="rate_title" >نرخ های پوند
                        <b className="admin_unit">GBP</b>
                      </span></>
                    ) : (
                      wallets.length > 0 ? (
                        <div className="pr-4">
                          <span className="balance_name">موجودی پوند</span>
                          <div className="flex items-center">
                            <span className="balance_amount number ml-2">
                              {wallets[0]['GBP'] != null ? parseFloat(wallets[0]['GBP']).toLocaleString() : 0}
                            </span>
                            <span className="balance_currency">GBP</span>
                          </div>
                        </div>
                      ) : (
                        <div className="mini_loader">
                          <div className="loader_small"></div>
                        </div>
                      )
                    )}


                  </div>

                  <div className="lg:flex items-center rate_cols" >
                    <div className="counter text-center lg:flex items-center justify-center w-full py-2 lg:w-3/6 lg:p-0">
                      <i className="pi pi-arrow-circle-down lg:ml-3" style={{ fontSize: '1rem' }}></i>
                      <div>
                        <b className="main_value">
                          {parseFloat(GBP_Rate["SellRate"]).toLocaleString()}
                        </b>
                        <small>خرید ازما</small>
                      </div>
                    </div>
                    <span className="spacer"></span>
                    <div className="counter text-center lg:flex items-center justify-center w-full py-2 lg:w-3/6 lg:p-0">
                      <i className="pi pi-arrow-circle-up lg:ml-3" style={{ fontSize: '1rem' }}></i>
                      <div>
                        <b className="main_value">
                          {parseFloat(GBP_Rate["BuyRate"]).toLocaleString()}
                        </b>
                        <small>فروش به ما</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="lg:flex gap-5 w-full">

              {Role != "admin" ? (
                <>

                  {ContentLoading == true ? (
                    <div className={`card p-0 mt-6 ${Role === "admin" ? "w-full" : "lg:w-2/3"}`}>
                      <span className="content_loader big"></span>
                    </div>
                  ) : (
                    <div className={`card p-5 mt-6 has_footer ${Role === "admin" ? "w-full" : "lg:w-2/3"}`}>
                      <div className="card_title  flex items-center">
                        <span className="card_heading_text">آخرین مبادله های من</span>
                        <span className="card_heading_icon mr-auto">
                          <svg
                            width={25}
                            height={25}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                              stroke="#292D32"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7.33008 14.4898L9.71008 11.3998C10.0501 10.9598 10.6801 10.8798 11.1201 11.2198L12.9501 12.6598C13.3901 12.9998 14.0201 12.9198 14.3601 12.4898L16.6701 9.50977"
                              stroke="#2554FF"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="table_scroll">

                        {trades.length > 0 ? (
                          <>
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                  {/* {Role == "admin" ? (
                                            <th scope="col" className="px-6 py-3">
                                              پست الکترونیکی
                                            </th>
                                          )  : (
                                              <></>
                                          )} */}
                                  <th scope="col" className="px-6 py-3">
                                    ارز تبدیل
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                    نوع سفارش
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                    معادل کیف پول
                                    <small>(تومان)</small>
                                  </th>
                                  {/* <th scope="col" className="px-6 py-3">
                                              وضعیت سفارش
                                          </th> */}
                                  <th scope="col" className="px-6 py-3">
                                    تاریخ تبدیل
                                  </th>
                                  {/* {Role == "admin" ? (
                                            <th scope="col" className="px-6 py-3">
                                              بیشتر
                                            </th>
                                          )  : (
                                              <></>
                                          )}                                     */}
                                </tr>
                              </thead>
                              <tbody>
                                {trades.map((trade, index) => (
                                  <tr
                                    key={index}
                                    className="bg-white dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                                  >

                                    <td className="px-6 py-4 number">
                                      {parseFloat(trade["Amount"]).toLocaleString()}
                                      <small className="ml-2" >{trade["BaseCurrency"]}</small>
                                    </td>
                                    <td className="px-6 py-3">
                                      <Type typeString={trade["Operation"]} />
                                    </td>
                                    <td className="px-6 py-3">
                                      {parseInt(trade["Price"]).toLocaleString()}
                                    </td>
                                    <td className="px-6 py-3">
                                      {timeAgo(trade["Date"])}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </>

                        ) : (
                          <div className="hint_box flex items-center">
                            <i className="pi pi-bell" style={{ fontSize: '1.5rem' }}></i>
                            <div className="pr-5" >
                              <b className="mb-2 block" >
                                شما هیچ سفارشی ثبت نکرده اید.
                              </b>
                              <span>
                                لازم به ذکر است برای ثبت سفارش نیاز به احراز هویت می باشد.

                              </span>
                            </div>
                          </div>
                        )}


                        <div className="card_footer">
                          <Link to="/trades">
                            <button
                              type="button"
                              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 button"
                            >
                              <i className="bx bx-left-arrow-alt li_arrow"></i> بیشتـر
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  )}


                  <div className="lg:w-1/3" >
                    {/* Wallet balance ==> */}
                    <div className="card p-2 py-5 lg:p-5 mt-6">
                      <div className="lg:flex items-center auth_card items-center">
                        <div className="card_icon purple_bg mr-0">
                          <svg width={35} height={35} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.0002 10.9692V13.0292C22.0002 13.5792 21.5602 14.0292 21.0002 14.0492H19.0402C17.9602 14.0492 16.9702 13.2592 16.8802 12.1792C16.8202 11.5492 17.0602 10.9592 17.4802 10.5492C17.8502 10.1692 18.3602 9.94922 18.9202 9.94922H21.0002C21.5602 9.96922 22.0002 10.4192 22.0002 10.9692Z" fill="#292D32" />
                            <path d="M20.47 15.55H19.04C17.14 15.55 15.54 14.12 15.38 12.3C15.29 11.26 15.67 10.22 16.43 9.48C17.07 8.82 17.96 8.45 18.92 8.45H20.47C20.76 8.45 21 8.21 20.97 7.92C20.75 5.49 19.14 3.83 16.75 3.55C16.51 3.51 16.26 3.5 16 3.5H7C6.72 3.5 6.45 3.52 6.19 3.56C3.64 3.88 2 5.78 2 8.5V15.5C2 18.26 4.24 20.5 7 20.5H16C18.8 20.5 20.73 18.75 20.97 16.08C21 15.79 20.76 15.55 20.47 15.55ZM13 9.75H7C6.59 9.75 6.25 9.41 6.25 9C6.25 8.59 6.59 8.25 7 8.25H13C13.41 8.25 13.75 8.59 13.75 9C13.75 9.41 13.41 9.75 13 9.75Z" fill="#292D32" />
                          </svg>
                        </div>
                        <div className="rate_cols pr-10 lg:p-0 mt-0">
                          <span className="counter text-start lg:text-center flex items-center justify-start pr-5">
                            <div>
                              <b className="block text_val">
                                <span className="number flex items-center justify-start"> <small className="unit ml-1">T</small>
                                  <b className="balance_amount">

                                    {balance == null ? (
                                      0
                                    ) : (
                                      parseInt(balance).toLocaleString()
                                    )}


                                  </b></span>
                              </b>
                              <small>موجودی کیف پول تومانی من</small>
                            </div>
                          </span>
                        </div>
                        <Link className="mr-auto" to="/payments">
                          <button className="add_to_wallet mr-3" >+</button>
                        </Link>
                      </div>
                    </div>
                    {/* Calc Box ==> */}
                    <div className="card p-5 mt-4 two_tabs">
                      <div className="card_title flex items-center">
                        <span className="card_heading_text">مبادله ارز</span>
                        <span className="card_heading_icon mr-auto">
                          <svg
                            width={25}
                            height={25}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="#2554FF">
                              <path
                                d="M2 8.50488H22"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinejoin="10"
                                strokeLinecap="round"
                              />
                              <path
                                d="M6 16.5049H8"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinejoin="10"
                                strokeLinecap="round"
                              />
                              <path
                                d="M10.5 16.5049H14.5"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinejoin="10"
                                strokeLinecap="round"
                              />
                            </g>
                            <path
                              d="M6.44 3.50488H17.55C21.11 3.50488 22 4.38488 22 7.89488V16.1049C22 19.6149 21.11 20.4949 17.56 20.4949H6.44C2.89 20.5049 2 19.6249 2 16.1149V7.89488C2 4.38488 2.89 3.50488 6.44 3.50488Z"
                              stroke="#292D32"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </div>
                      <div className="order_box">

                        {Role == "admin" ? (
                          <h1>مدیریت نرخ ها</h1>
                        ) : (
                          <>

                            <TabView>
                              <TabPanel header="خرید ارز با کیف پول">
                                <form>
                                  <div className="mb-5 lg:w-1/2 lg:inline-block lg:pl-3">
                                    <label
                                      htmlFor="rate"
                                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                      انتخاب ارز
                                    </label>


                                    <Dropdown
                                      value={selectedDepositRate}
                                      onChange={(e) => setselectedDepositRate(e.value)}
                                      options={rates}
                                      optionLabel="Name"
                                      placeholder="نمایش لیست"
                                      className="items-center w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom drop_custom"
                                      checkmark={true}
                                      highlightOnSelect={false}
                                    />
                                  </div>

                                  <div className="mb-5 lg:w-1/2 lg:inline-block lg:pr-3">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                      مقدار
                                    </label>
                                    <input
                                      value={amountDeposit}
                                      onChange={(e) => setAmountDeposit(e.target.value)}
                                      type="number"
                                      className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom number"
                                      placeholder="0"
                                      min="0"
                                      required
                                      disabled={selectedDepositRate == null}
                                    />
                                  </div>


                                  <div className="mb-5">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                      موجودی موردنیاز<small className="label_small">TOMAN</small>{" "}
                                    </label>
                                    <input
                                      value={finalDeposit.toLocaleString()}
                                      type="text"
                                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom number"
                                      placeholder="0"
                                      required
                                      readOnly
                                      disabled
                                    />
                                  </div>
                                  <button type="button" onClick={openDepositDialog} className="add_addtrans flex items-center">
                                    <span>ثبت و مبادله</span>
                                    <svg
                                      className="mr-auto"
                                      width={25}
                                      height={25}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.78 2H8.22C4.44 2 3.5 3.01 3.5 7.04V18.3C3.5 20.96 4.96 21.59 6.73 19.69L6.74 19.68C7.56 18.81 8.81 18.88 9.52 19.83L10.53 21.18C11.34 22.25 12.65 22.25 13.46 21.18L14.47 19.83C15.19 18.87 16.44 18.8 17.26 19.68C19.04 21.58 20.49 20.95 20.49 18.29V7.04C20.5 3.01 19.56 2 15.78 2ZM14.75 10.75H9.25C8.84 10.75 8.5 10.41 8.5 10C8.5 9.59 8.84 9.25 9.25 9.25H14.75C15.16 9.25 15.5 9.59 15.5 10C15.5 10.41 15.16 10.75 14.75 10.75Z"
                                        fill="#FFFFFF"
                                      />
                                    </svg>
                                  </button>

                                  <Dialog header="آیا مطمئن هستید؟" visible={visibleDeposit} className="dialoge_box" onHide={() => { if (!visibleDeposit) return; setVisibleDeposit(false); }} footer={footerContentDeposit}>

                                    <div className="dialog_type">
                                      <b>خرید ارز</b>
                                    </div>
                                    <div className="modal_summary">
                                      <span className="number">
                                        {amountDeposit != null ? (
                                          <>{parseInt(amountDeposit).toLocaleString()}</>
                                        ) : (
                                          <>Waiting...</>
                                        )}
                                      </span>
                                      <b className="number">
                                        {selectedDepositRate != null ? (
                                          <>{selectedDepositRate['Currency']}</>
                                        ) : (
                                          <>Waiting...</>
                                        )}
                                      </b>
                                    </div>

                                    <ul className="pre_trans_action">
                                      <li className="flex items-center" >
                                        <span className="pta_title" >
                                          مقدار ارز
                                        </span>
                                        <span className="pta_value mr-auto number" >
                                          {amountDeposit != null ? (
                                            <>{parseInt(amountDeposit).toLocaleString()}</>
                                          ) : (
                                            <>Waiting...</>
                                          )}
                                        </span>
                                      </li>
                                      <li className="flex items-center" >
                                        <span className="pta_title" >
                                          ارز موردنظر
                                        </span>
                                        <span className="pta_value mr-auto number" >
                                          {selectedDepositRate != null ? (
                                            <>{selectedDepositRate['Currency']}</>
                                          ) : (
                                            <>Waiting...</>
                                          )}
                                        </span>
                                      </li>
                                      <li className="flex items-center" >
                                        <span className="pta_title" >
                                          پست الکترونیک
                                        </span>
                                        <span className="pta_value mr-auto number" >
                                          {Cookies.get("user_Email")}
                                        </span>
                                      </li>
                                      <li className="flex items-center" >
                                        <span className="pta_title" >
                                          قابل پرداخت
                                          <small> (تومان)</small>
                                        </span>
                                        <span className="pta_value mr-auto number" >
                                          {finalDeposit.toLocaleString()}
                                        </span>
                                      </li>
                                    </ul>

                                    <p className="small_hint flex items-center dialog_hint">
                                      <i className="pi pi-info-circle" style={{ fontSize: '1rem' }}></i>
                                      <span className="pr-3" >
                                        پس از تایید , مبلغ مبادله از کیف پول شما کسر می گردد و ارز مورد نظر به پنل شما اضافه می شود
                                      </span>
                                    </p>

                                  </Dialog>
                                </form>
                              </TabPanel>
                              <TabPanel header="فروش ارز">
                                <form>
                                  <div className="mb-5 lg:w-1/2 lg:inline-block lg:pl-3">
                                    <label
                                      htmlFor="rate"
                                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >
                                      انتخاب ارز
                                    </label>
                                    <Dropdown
                                      value={selectedWithdrawRate}
                                      onChange={(e) => setselectedWithdrawRate(e.value)}
                                      options={rates}
                                      optionLabel="Name"
                                      placeholder="نمایش لیست"
                                      className="items-center w-full shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom drop_custom"
                                      checkmark={true}
                                      highlightOnSelect={false}
                                    />
                                  </div>
                                  <div className="mb-5 lg:w-1/2 lg:inline-block lg:pr-3">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                      مقدار
                                    </label>
                                    <input
                                      value={amountWithdraw}
                                      onChange={(e) => setAmountWithdraw(e.target.value)}
                                      type="number"
                                      className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom number"
                                      placeholder="0"
                                      min="0"
                                      required
                                      disabled={selectedWithdrawRate == null}
                                    />
                                  </div>
                                  <div className="mb-5">
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                      معادل تومانی<small className="label_small">TOMAN</small>{" "}
                                    </label>
                                    <input
                                      value={finalWithdraw.toLocaleString()}
                                      type="text"
                                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom number"
                                      placeholder="0"
                                      required
                                      readOnly
                                      disabled
                                    />
                                  </div>
                                  <button type="button" onClick={openWithdrawDialog} className="add_addtrans flex items-center">
                                    <span>ثبت و مبادله</span>
                                    <svg
                                      className="mr-auto"
                                      width={25}
                                      height={25}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M15.78 2H8.22C4.44 2 3.5 3.01 3.5 7.04V18.3C3.5 20.96 4.96 21.59 6.73 19.69L6.74 19.68C7.56 18.81 8.81 18.88 9.52 19.83L10.53 21.18C11.34 22.25 12.65 22.25 13.46 21.18L14.47 19.83C15.19 18.87 16.44 18.8 17.26 19.68C19.04 21.58 20.49 20.95 20.49 18.29V7.04C20.5 3.01 19.56 2 15.78 2ZM14.75 10.75H9.25C8.84 10.75 8.5 10.41 8.5 10C8.5 9.59 8.84 9.25 9.25 9.25H14.75C15.16 9.25 15.5 9.59 15.5 10C15.5 10.41 15.16 10.75 14.75 10.75Z"
                                        fill="#FFFFFF"
                                      />
                                    </svg>
                                  </button>

                                  <Dialog header="آیا مطمئن هستید؟" visible={visibleWithdraw} className="dialoge_box" onHide={() => { if (!visibleWithdraw) return; setVisibleWithdraw(false); }} footer={footerContentWithdraw}>

                                    <div className="dialog_type">
                                      <b>فروش ارز و شارژ کیف پول</b>
                                    </div>
                                    <div className="modal_summary">
                                      <span className="number">
                                        {amountWithdraw != null ? (
                                          <>{parseInt(amountWithdraw).toLocaleString()}</>
                                        ) : (
                                          <>Waiting...</>
                                        )}
                                      </span>
                                      <b className="number">
                                        {selectedWithdrawRate != null ? (
                                          <>{selectedWithdrawRate['Currency']}</>
                                        ) : (
                                          <>Waiting...</>
                                        )}
                                      </b>
                                    </div>

                                    <ul className="pre_trans_action">
                                      <li className="flex items-center" >
                                        <span className="pta_title" >
                                          مقدار ارز
                                        </span>
                                        <span className="pta_value mr-auto number" >
                                          {amountWithdraw != null ? (
                                            <>{parseInt(amountWithdraw).toLocaleString()}</>
                                          ) : (
                                            <>Waiting...</>
                                          )}
                                        </span>
                                      </li>
                                      <li className="flex items-center" >
                                        <span className="pta_title" >
                                          ارز موردنظر
                                        </span>
                                        <span className="pta_value mr-auto number" >
                                          {selectedWithdrawRate != null ? (
                                            <>{selectedWithdrawRate['Currency']}</>
                                          ) : (
                                            <>Waiting...</>
                                          )}
                                        </span>
                                      </li>
                                      <li className="flex items-center" >
                                        <span className="pta_title" >
                                          پست الکترونیک
                                        </span>
                                        <span className="pta_value mr-auto number" >
                                          {Cookies.get("user_Email")}
                                        </span>
                                      </li>
                                      <li className="flex items-center" >
                                        <span className="pta_title" >
                                          مبلغ دریافتی
                                          <small> (تومان)</small>
                                        </span>
                                        <span className="pta_value mr-auto number" >
                                          {finalWithdraw.toLocaleString()}
                                        </span>
                                      </li>
                                    </ul>

                                    <p className="small_hint flex items-center dialog_hint">
                                      <i className="pi pi-info-circle" style={{ fontSize: '1rem' }}></i>
                                      <span className="pr-3" >
                                        پس از تایید , مبلغ نهایی به کیف پول شما افزوده خواهد شد
                                      </span>
                                    </p>

                                  </Dialog>
                                </form>
                              </TabPanel>
                            </TabView>
                          </>
                        )}


                      </div>
                    </div>
                  </div>



                </>
              ) : (
                <></>
              )}

            </div>


            <div className="lg:flex gap-5">

              {Role == "admin" ? (
                <div className="w-full">

                  {ContentLoading == true ? (
                    <div className="lg:grid lg:grid-cols-1 gap-5 mt-8">
                      <span className="content_loader"></span>
                    </div>
                  ) : (

                    <>
                      <div className="grid lg:grid-cols-2 gap-5">
                        <div className="card p-5 mt-6 w-full has_footer">
                          <div className="card_title flex items-center">
                            <span className="card_heading_text">
                              گزارش واریز و برداشت ها
                            </span>
                            <span className="card_heading_icon mr-auto">
                              <svg
                                width={25}
                                height={25}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.33008 14.4898L9.71008 11.3998C10.0501 10.9598 10.6801 10.8798 11.1201 11.2198L12.9501 12.6598C13.3901 12.9998 14.0201 12.9198 14.3601 12.4898L16.6701 9.50977"
                                  stroke="#2554FF"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </div>
                          <div className="table_scroll">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                  {/* <th scope="col" className="px-6 py-3">
                                      درخواست کننده
                                  </th> */}
                                  <th scope="col" className="px-6 py-3">
                                    نوع درخواست
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                    مقدار
                                  </th>
                                  <th scope="col" className="px-6 py-3">
                                    تاریخ درخواست
                                  </th>
                                  {/* <th scope="col" className="px-6 py-3">
                                      شناسه درخواست
                                  </th> */}
                                  <th scope="col" className="px-6 py-3">
                                    وضعیت
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {payments.map((payment, index) => (
                                  <tr
                                    key={index}
                                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success">
                                    {/* <td className="px-6 py-4 number">
                                          {payment["Email"]}
                                        </td> */}
                                    <td className="px-6 py-3">
                                      <Type typeString={payment["Type"]} />
                                    </td>
                                    <td className="px-6 py-3 number">
                                      {parseInt(payment["Amount"]).toLocaleString()}
                                      <b className="unit">{payment["Currency"]}</b>
                                    </td>
                                    <td className="px-6 py-3">
                                      {timeAgo(payment["Date"])}
                                    </td>
                                    {/* <td className="px-6 py-3">
                                        {payment["id"]}
                                        </td> */}
                                    <td className="px-6 py-3 number">
                                      {payment["Status"]}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="card_footer">
                              <Link to="/payments">
                                <button
                                  type="button"
                                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 button"
                                >
                                  <i className="bx bx-left-arrow-alt li_arrow"></i> بیشتـر
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="card p-5 mt-6 w-full has_footer">
                          <div className="card_title flex items-center">
                            <span className="card_heading_text">
                              تیکت های اخیر
                            </span>
                            <span className="card_heading_icon mr-auto">
                              <svg
                                width={25}
                                height={25}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.33008 14.4898L9.71008 11.3998C10.0501 10.9598 10.6801 10.8798 11.1201 11.2198L12.9501 12.6598C13.3901 12.9998 14.0201 12.9198 14.3601 12.4898L16.6701 9.50977"
                                  stroke="#2554FF"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </span>
                          </div>

                          <div className="table_scroll">
                            {tickets.length > 0 ? (
                              <>
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                      <th scope="col" className="px-6 py-3">
                                        عنوان تیکت
                                      </th>
                                      <th scope="col" className="px-6 py-3">
                                        تاریخ ثبت
                                      </th>
                                      <th scope="col" className="px-6 py-3">
                                        وضعیت
                                      </th>
                                      {Role == "admin" ? (
                                        <th scope="col" className="px-6 py-3">
                                          ارسال کننده
                                        </th>
                                      ) : (
                                        <></>
                                      )}

                                    </tr>
                                  </thead>
                                  <tbody>
                                    {tickets.map((ticket, index) => (
                                      <tr
                                        key={index}
                                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                                      >
                                        <td className="px-6 py-4">
                                          {ticket.Title}
                                        </td>
                                        <td className="px-6 py-3">
                                          {timeAgo(ticket.Date)}
                                        </td>
                                        <td className="px-6 py-3">
                                          <StatusDiv statusCode={ticket.Status} />
                                        </td>
                                        {Role == "admin" ? (
                                          <td className="px-6 py-3">
                                            {ticket.Email}
                                          </td>
                                        ) : (
                                          <></>
                                        )}

                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </>

                            ) : (
                              <div className="hint_box flex items-center">
                                <i className="pi pi-bell" style={{ fontSize: '1.5rem' }}></i>
                                <div className="pr-5" >
                                  <b className="mb-2 block" >
                                    یافت نشد
                                  </b>
                                  <span>
                                    درحال حاضر در سیستم تیکتی وجود ندارد
                                  </span>
                                </div>
                              </div>
                            )}

                          </div>

                          <div className="card_footer">
                            <Link to="/tickets">
                              <button
                                type="button"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 button"
                              >
                                <i className="bx bx-left-arrow-alt li_arrow"></i> بیشتـر
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="card p-5 mt-6 w-full has_footer">
                        <div className="card_title flex items-center">
                          <span className="card_heading_text">
                            کاربران اخیر
                          </span>
                          <span className="card_heading_icon mr-auto">
                            <svg
                              width={25}
                              height={25}
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.33008 14.4898L9.71008 11.3998C10.0501 10.9598 10.6801 10.8798 11.1201 11.2198L12.9501 12.6598C13.3901 12.9998 14.0201 12.9198 14.3601 12.4898L16.6701 9.50977"
                                stroke="#2554FF"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        </div>
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                پست الکترونیکی
                              </th>
                              <th scope="col" className="px-6 py-3">
                                نام کامل
                              </th>
                              <th scope="col" className="px-6 py-3">
                                شماره همراه
                              </th>
                              <th scope="col" className="px-6 py-3">
                                کدملی
                              </th>
                              <th scope="col" className="px-6 py-3">
                                تاریخ عضویت
                              </th>
                              <th scope="col" className="px-6 py-3">
                                مسدودسازی
                              </th>
                              <th scope="col" className="px-6 py-3">
                                احرازهویت
                              </th>

                            </tr>
                          </thead>
                          <tbody>
                            {users.map((user, index) => (
                              <tr
                                key={index}
                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                              >
                                <td className="px-6 py-4">
                                  {user["Email"]}
                                </td>
                                <td className="px-6 py-3">
                                  {user["FirstName"]}
                                  <span> - </span>
                                  {user["LastName"]}
                                </td>
                                <td className="px-6 py-3 number">
                                  {user["Mobile"]}
                                </td>
                                <td className="px-6 py-3">
                                  {user["NationalCode"]}
                                </td>
                                <td className="px-6 py-3">
                                  {timeAgo(user["RegisterDate"])}
                                </td>
                                <td className="px-6 py-3">
                                  <StatusDiv statusCode={user["Suspend"]} />
                                </td>
                                <td className="px-6 py-3">
                                  <StatusDiv statusCode={user["Identify"]} />
                                </td>


                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div className="card_footer">
                          <a href="/payments">
                            <button type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 button"><i className="bx bx-left-arrow-alt li_arrow"></i> بیشتـر</button>
                          </a>
                        </div>
                      </div>
                    </>


                  )}


                  <Dialog header="جزییات" visible={visibleOrderData} className="dialoge_box big_dialoge" onHide={() => { if (!visibleOrderData) return; setVisibleOrderData(false); }} footer={footerTradeDialoge}>

                    <div className="dialog_type">
                      {userOrderData['Type'] == "Deposit" ? (
                        <b>مقدار پرداختی</b>
                      ) : (
                        <b>مقدار درخواستی</b>
                      )}
                    </div>
                    <div className="modal_summary">
                      <span className="number">
                        {parseInt(userOrderData['Price']).toLocaleString()}
                      </span>
                      <b className="number">
                        TOMAN
                      </b>
                    </div>

                    <ul className="pre_trans_action">
                      <li className="flex items-center" >
                        <span className="pta_title" >
                          مقدار سفارش
                        </span>
                        <span className="pta_value mr-auto number" >
                          {parseInt(userOrderData['Amount']).toLocaleString()}
                        </span>
                      </li>
                      <li className="flex items-center" >
                        <span className="pta_title" >
                          ارز موردنظر
                        </span>
                        <span className="pta_value mr-auto number" >
                          {userOrderData['Currency']}
                        </span>
                      </li>
                      <li className="flex items-center" >
                        <span className="pta_title" >
                          پست الکترونیک
                        </span>
                        <span className="pta_value mr-auto number" >
                          {userOrderData['Email']}
                        </span>
                      </li>
                      <li className="flex items-center" >
                        <span className="pta_title" >
                          وضعیت
                        </span>
                        <span className="pta_value mr-auto" >
                          <StatusDiv statusCode={userOrderData['Status']} />
                        </span>
                      </li>
                      <li className="flex items-center" >
                        <span className="pta_title" >
                          نوع
                        </span>
                        <span className="pta_value mr-auto" >
                          {userOrderData['Type']}
                        </span>
                      </li>
                      <li className="flex items-center" >
                        <span className="pta_title" >
                          تاریخ بروزرسانی
                        </span>
                        <span className="pta_value mr-auto number" >
                          {userOrderData['Date']}
                        </span>
                      </li>
                      {userPaymentData != null ? (
                        <>
                          <li className="flex items-center" >
                            <span className="pta_title" >
                              تاریخ بروزرسانی پرداخت
                            </span>
                            <span className="pta_value mr-auto number" >
                              {userPaymentData['Date']}
                            </span>
                          </li>
                          <li className="flex items-center" >
                            <span className="pta_title" >
                              کد رهگیری
                            </span>
                            <span className="pta_value mr-auto number" >
                              {userPaymentData['TrackingCode']}
                            </span>
                          </li>
                          <li className="flex items-center" >
                            <span className="pta_title" >
                              فیش واریزی
                            </span>
                            <span className="pta_value mr-auto number" >
                              <a target="_bank" href={process.env.REACT_APP_BASE_URL + '/public/api/v1/file/uploads/' + userPaymentData['Image']} className="button mini_button" >
                                مشاهده تصویر
                              </a>
                            </span>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                    </ul>

                    <p className="small_hint flex items-center dialog_hint">
                      <i className="pi pi-info-circle" style={{ fontSize: '1rem' }}></i>
                      <span className="pr-3" >
                        پس از تایید پرداخت , وضعیت سفارش کاربر تکمیل می شود و مقدار مورد نظر در کیف پول کاربر شارژ یا کسر خواهد شد
                      </span>
                    </p>

                  </Dialog>
                  <Dialog header="دلیل رد سفارش" visible={visibleRejectReason} className="dialoge_box big_dialoge" onHide={() => { if (!visibleRejectReason) return; setVisibleRejectReason(false); }}>

                    <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mb-2 block">توضیحات برای کاربر</label>
                    <textarea
                      value={rejectReason}
                      onChange={(e) => setRejectReason(e.target.value)}
                      id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="بنویسید ..."></textarea>
                    <button type="button" className="button w-full mt-5 mb-5" onClick={() => OrderAction(userOrderData['id'], 401, userOrderData['Type'], rejectReason)} >ثبت و ارسال</button>

                  </Dialog>


                </div>
              ) : (
                <></>
              )}



            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
