import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import ManualPayment from '../components/manualpayment';
import StatusDiv from '../utils/status';
import { timeAgo } from '../utils/timeAgo';
import Type from '../utils/type';

// toast lib ==>
import { Toast } from 'primereact/toast';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';

import CryptoJS from 'crypto-js';

// Dialog ==>
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

const Trades = () => {
    const Auth_Cookie = Cookies.get('avaAuth');
    const [isLoading, setIsLoading] = useState(true);
    const [isValidCookie, setIsValidCookie] = useState(false);
    const toast = useRef(null);
    const [trades, settrades] = useState([]);
    const [payment, setPayment] = useState([]);
    const [showPayment, setShowPayment] = useState(false);
    const [Role, setRole] = useState("user");
    const user_role = Cookies.get("theme");
    const [InnerLoader, setInnerLoader] = useState(false);
    const [ContentLoading, setContentLoading] = useState(true);
    // add order ==>
    const navigate = useNavigate();

    // Admin dialoge ==>
    const [visibleOrderData, setVisibleOrderData] = useState(false);
    const [userPaymentData, setUserPaymentData] = useState([]);
    const [userOrderData, setUserOrderData] = useState([]);
    const [visibleRejectReason,setVisibleRejectReason] = useState(false);
    const [rejectReason, setRejectReason] = useState("");

    const decrypt = (ciphertext) => {
        const bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    const footerOrderDialoge = (
        <div className="grid grid-cols-2 gap-5" >
            <Button label="تایید پرداخت" type="submit" onClick={() => OrderAction(userOrderData['id'],200,userOrderData['Type'])} icon="pi pi-check" autoFocus />
            <Button label="رد پرداخت" icon="pi pi-times"  onClick={() => setVisibleRejectReason(true)}  className="p-button-text" />
        </div>
    );

    useEffect(() => {
        if (decrypt(user_role) == 'user') {
            setRole('user')
        } else if(decrypt(user_role) == 'admin'){
            setRole('admin')
        }else{
            setRole('user')
        }
    }, [Role, setRole]);


    // useEffect(() => {
    //     const reloadInterval = setInterval(() => {
    //       window.location.reload();
    //     }, 10 * 60 * 1000); // 10 minutes in milliseconds
    
    //     // Clear the interval on component unmount to prevent memory leaks
    //     return () => clearInterval(reloadInterval);
    // }, []);
    
    const OrderAction = async (OrderId,Status,Type,Reason) => {
        try {
            setInnerLoader(true);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/trades/trade.php?` +
            `OrderId=${encodeURIComponent(OrderId)}&` +
              `Status=${encodeURIComponent(parseInt(Status))}&` +
              `Type=${Type}&` +
              `Reason=${Reason}`
            ,
            {
                method: "PUT",
                headers: {
                  'Authorization': `Bearer ${Auth_Cookie}`,
                },
                redirect: "follow",
              },
            );
    
            const data = await response.json();
            if (data.status  == 200) {
               window.location.reload();
            } else {          
                alert(data.message)
                setInnerLoader(false);
            }
    
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message);
            } else {
                console.log('An unexpected error occurred');
            }
        }
      };

    useEffect(() => {

        if (!Auth_Cookie) {
            navigate('/login');
            return; // Exit early if no cookie is present
        }

        const fetchtrades = async () => {
            try {
              // Mocking API call to validate cookie, replace with actual API call
              const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/public/api/v1/trades/trade.php`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${Auth_Cookie}`,
                  },
                },
              );
      
              if (response.ok) {
                const data = await response.json();
                if (data.status == 200) {
                  settrades(data.message);
                  setIsValidCookie(true);
                  setIsLoading(false);
                  setContentLoading(false);
                } else {
                  if (data.status == 403) {
                    Cookies.remove("avaAuth");
                    navigate("/login");
                  } else {
                    toast.current.show({
                      severity: "error",
                      summary: "Error",
                      detail: data.message,
                      life: 5000,
                    });
                  }
                }
              } else {
                navigate("/login");
              }
            } catch (error) {
              navigate("/login");
            } finally {
              setIsLoading(false);
            }
          };
      
        fetchtrades();
    }, [navigate]);


    return(
        <>
            {InnerLoader != "" ? (
                <div className="loading_wrapper">
                    <span className="loader"></span>
                </div>
            )  : (
                <></>
            )}   
            <Toast ref={toast} />
            <div className="lg:flex" >
                <Sidebar/>
                <div className="mainbar" >
                    <Navbar/>
                    <div className="main">
                        {showPayment ? (
                            <ManualPayment paymentData={payment} />
                        )  : (
                            <></>
                        )}
                        <div className="single_page_title">
                            
                            <h1>تبدیل ها</h1>
                            <h2>trades</h2>

                            <div className="mr-auto"><i className="bx bx-cart-alt li_icon"></i></div>
                        </div>


                        {ContentLoading == true ? (
                            <div className="lg:grid lg:grid-cols-1 gap-5 mt-8 card p-0">
                                <span className="content_loader"></span>
                            </div>
                        )  : (
                            <>
                                <div >
                                    <div className="lg:flex gap-5">
                                        <div className="card p-5 mt-6 w-full">
                                            <div className="table_scroll">
                                                {trades.length > 0 ? (
                                                <>
                                                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3">
                                                                    مقدار ارز
                                                                </th>
                                                                <th scope="col" className="px-6 py-3">
                                                                    ارز تبدیل
                                                                </th>
                                                                {/* <th scope="col" className="px-6 py-3">
                                                                    مبلغ سفارش 
                                                                    <small>(تومان)</small>
                                                                </th> */}
                                                                <th scope="col" className="px-6 py-3">
                                                                    نوع تبدیل
                                                                </th>
                                                                <th scope="col" className="px-6 py-3">
                                                                تاریخ تبدیل
                                                                </th>
                                                                <th scope="col" className="px-6 py-3">
                                                                    معادل تومانی 
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {trades.map((trade, index) => (
                                                            <tr
                                                                key={index}
                                                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                                                            >
                                                                <td className="px-6 py-4 number">
                                                                {parseFloat(trade["Amount"]).toLocaleString()}
                                                                <small className="ml-2" >{trade["BaseCurrency"]}</small>
                                                                </td>
                                                                <td className="px-6 py-3 number">
                                                                {trade["BaseCurrency"]}
                                                                </td>
                                                                <td className="px-6 py-3">
                                                                <Type typeString={trade["Operation"]} />
                                                                </td>
                                                                <td className="px-6 py-3">
                                                                {timeAgo(trade["Date"])}
                                                                </td>
                                                                <td className="px-6 py-3">
                                                                    {parseInt(trade["Price"]).toLocaleString()}
                                                                </td>

                                                                

                                                            </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </>

                                                )  : (
                                                    <div className="hint_box flex items-center">
                                                        <i className="pi pi-bell" style={{ fontSize: '1.5rem' }}></i>
                                                        <div  className="pr-5" >
                                                            <b className="mb-2 block" >
                                                                شما هیچ تبدیلی انجام نداده اید
                                                            </b>
                                                            <span>
                                                                برای تبدیل ارز از صفحه داشبورد اقدام کنید
                                                            </span>
                                                        </div>
                                                    </div>
                                                )}
                                                
                                            </div>

                                            {Role == "admin" ? (
                                                <>
                                                    <Dialog header="جزییات" visible={visibleOrderData} className="dialoge_box big_dialoge" onHide={() => {if (!visibleOrderData) return; setVisibleOrderData(false); }} footer={footerOrderDialoge}>

                                                        <div className="dialog_type">
                                                        {userOrderData['Type'] == "Deposit" ? (
                                                            <b>مقدار پرداختی</b>
                                                        )  : (
                                                            <b>مقدار درخواستی</b>
                                                        )}
                                                        </div>

                                                        <div className="modal_summary">
                                                            <span className="number">
                                                                {parseInt(userOrderData['Price']).toLocaleString()}
                                                            </span>
                                                            <b className="number">
                                                                TOMAN
                                                            </b>
                                                        </div>

                                                        <ul className="pre_trans_action">
                                                                <li className="flex items-center" >
                                                                    <span className="pta_title" >
                                                                        مقدار سفارش
                                                                    </span>
                                                                    <span className="pta_value mr-auto number" >
                                                                    {parseInt(userOrderData['Amount']).toLocaleString()}
                                                                    </span>
                                                                </li>
                                                                <li className="flex items-center" >
                                                                    <span className="pta_title" >
                                                                        ارز موردنظر
                                                                    </span>
                                                                    <span className="pta_value mr-auto number" >
                                                                    {userOrderData['Currency']}
                                                                    </span>
                                                                </li>
                                                                <li className="flex items-center" >
                                                                    <span className="pta_title" >
                                                                        پست الکترونیک
                                                                    </span>
                                                                    <span className="pta_value mr-auto number" >
                                                                    {userOrderData['Email']}
                                                                    </span>
                                                                </li>
                                                                <li className="flex items-center" >
                                                                    <span className="pta_title" >
                                                                        وضعیت
                                                                    </span>
                                                                    <span className="pta_value mr-auto" >
                                                                    <StatusDiv statusCode={userOrderData['Status']} />
                                                                    </span>
                                                                </li>
                                                                <li className="flex items-center" >
                                                                    <span className="pta_title" >
                                                                        نوع
                                                                    </span>
                                                                    <span className="pta_value mr-auto" >
                                                                    {userOrderData['Type']}
                                                                    </span>
                                                                </li>
                                                                <li className="flex items-center" >
                                                                    <span className="pta_title" >
                                                                        تاریخ بروزرسانی

                                                                    </span>
                                                                    <span className="pta_value mr-auto number" >
                                                                    {userOrderData['Date']}
                                                                    </span>
                                                                </li>
                                                                {userPaymentData != null ? (
                                                                    <>
                                                                    <li className="flex items-center" >
                                                                        <span className="pta_title" >
                                                                            تاریخ ثبت پرداخت
                                                                        </span>
                                                                        <span className="pta_value mr-auto number" >
                                                                        {userPaymentData['Date']}
                                                                        </span>
                                                                    </li>
                                                                    <li className="flex items-center" >
                                                                        <span className="pta_title" >
                                                                            کد رهگیری
                                                                        </span>
                                                                        <span className="pta_value mr-auto number" >
                                                                        {userPaymentData['TrackingCode']}
                                                                        </span>
                                                                    </li>
                                                                    <li className="flex items-center" >
                                                                        <span className="pta_title" >
                                                                            فیش واریزی
                                                                        </span>
                                                                        <span className="pta_value mr-auto number" >
                                                                        <a target="_bank" href={ process.env.REACT_APP_BASE_URL + '/public/api/v1/file/uploads/' + userPaymentData['Image']} className="button mini_button" >
                                                                            مشاهده تصویر
                                                                        </a>
                                                                        </span>
                                                                    </li>
                                                                    </>
                                                                )  : (
                                                                    <></>
                                                                )}                                

                                                        </ul>

                                                        <p className="small_hint flex items-center dialog_hint">
                                                            <i className="pi pi-info-circle" style={{ fontSize: '1rem' }}></i>
                                                            <span className="pr-3" >
                                                            پس از تایید پرداخت , وضعیت سفارش کاربر تکمیل می شود و مقدار مورد نظر در کیف پول کاربر شارژ یا کسر خواهد شد
                                                            </span>
                                                        </p>

                                                    </Dialog>     

                                                    <Dialog header="دلیل رد سفارش" visible={visibleRejectReason} className="dialoge_box big_dialoge" onHide={() => {if (!visibleRejectReason) return; setVisibleRejectReason(false); }}>

                                                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white mb-2 block">توضیحات برای کاربر</label>
                                                        <textarea 
                                                        value={rejectReason}
                                                        onChange={(e) => setRejectReason(e.target.value)}
                                                        id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="بنویسید ..."></textarea>
                                                        <button type="button" className="button w-full mt-5 mb-5" onClick={() => OrderAction(userOrderData['id'],401,userOrderData['Type'],rejectReason)} >ثبت و ارسال</button>

                                                    </Dialog>  
                                                </>
                                                
                                            )  : (
                                                <></>
                                            )}
                                    </div>
                                        
                                
                                    </div>
                                </div>
                            </>
                        )}


                    </div>
                </div>
            </div>
        </>
    );
        
};

export default Trades;
