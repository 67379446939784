
import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
// icons ==>
import 'primeicons/primeicons.css';
import { useSelector, useDispatch } from 'react-redux';
import { setUser, clearUser } from '../features/userSlice';


const Sidebar = () => {
    const Auth_Cookie = Cookies.get("avaAuth");
    const user_role = Cookies.get("theme");
    const [Menu, setIsMenu] = useState("");
    const [Role, setRole] = useState("user");
    const location = useLocation();
    const { pathname } = location;
    const isActive = (path) => pathname === path;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);

    useEffect(() => {
        if (Auth_Cookie) {
            const decrypt = (ciphertext) => {
                const bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_KEY);
                return bytes.toString(CryptoJS.enc.Utf8);
            };

            if (decrypt(user_role) === 'user') {
                setRole('user')
            } else if (decrypt(user_role) === 'admin') {
                setRole('admin')
            } else {
                setRole('user')
            }
        }

    }, [Role, setRole, Auth_Cookie, user_role]);



    const logOut = async () => {
        Cookies.remove('avaAuth');
        dispatch(clearUser());
        navigate('/login');
    };

    const OpeMenu = async (event) => {
        event.preventDefault();
        setIsMenu("opened")
    };

    const CloseMenu = async (event) => {
        event.preventDefault();
        setIsMenu("")
    };

    return (
        <div className={`sidebar ${Menu}`} >
            <div>
                <div className="navbar_brand flex items-center" >
                    <div className="mobile_title" >
                        <h1 className="site_title">آواپی</h1>
                        <small className="number">Avapay24.co</small>
                    </div>

                    <div className="mr-auto flex items-center lg:hidden">
                        <span className="log_out mr-auto" onClick={logOut}>
                            <svg fill="#FFFFFF" width={40} viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <title>logout</title>
                                <path d="M0 9.875v12.219c0 1.125 0.469 2.125 1.219 2.906 0.75 0.75 1.719 1.156 2.844 1.156h6.125v-2.531h-6.125c-0.844 0-1.5-0.688-1.5-1.531v-12.219c0-0.844 0.656-1.5 1.5-1.5h6.125v-2.563h-6.125c-1.125 0-2.094 0.438-2.844 1.188-0.75 0.781-1.219 1.75-1.219 2.875zM6.719 13.563v4.875c0 0.563 0.5 1.031 1.063 1.031h5.656v3.844c0 0.344 0.188 0.625 0.5 0.781 0.125 0.031 0.25 0.031 0.313 0.031 0.219 0 0.406-0.063 0.563-0.219l7.344-7.344c0.344-0.281 0.313-0.844 0-1.156l-7.344-7.313c-0.438-0.469-1.375-0.188-1.375 0.563v3.875h-5.656c-0.563 0-1.063 0.469-1.063 1.031z"></path>
                            </svg>
                        </span>

                        {Menu !== "" ? (
                            <i className="pi pi-times mr-2 menu_icon" onClick={CloseMenu} style={{ fontSize: '1.3rem' }}></i>
                        ) : (
                            <i className="pi pi-bars mr-2 menu_icon" onClick={OpeMenu} style={{ fontSize: '1.3rem' }}></i>
                        )}
                    </div>
                </div>
                <ul className="sidebar_ul" >
                    <Link to="/">
                        <li className={isActive("/") ? "active" : ""} ><i className="bx bx-home li_icon"></i><span>داشبورد</span><i className="bx bx-left-arrow-alt li_arrow"></i></li>
                    </Link>

                    <Link to="/identify">
                        <li className={isActive("/identify") ? "active" : ""} ><i className="bx bx bx-user li_icon"></i><span>اطلاعات کاربری</span><i className="bx bx-left-arrow-alt li_arrow"></i></li>
                    </Link>

                    {Role === "admin" ? (
                        <Link to="/usdtTrns">
                            <li className={isActive("/usdtTrns") ? "active" : ""} ><i className='bx bx-qr-scan li_icon'></i><span className="number" >USDT <small>(trc20)</small></span><i className="bx bx-left-arrow-alt li_arrow"></i></li>
                        </Link>
                    ) : (
                        <></>
                    )}


                    <Link to="/trades">
                        <li className={isActive("/trades") ? "active" : ""} ><i className='bx bx-transfer-alt li_icon'></i><span>تبدیل ها</span><i className="bx bx-left-arrow-alt li_arrow"></i></li>
                    </Link>

                    <Link to="/payments">
                        <li className={isActive("/payments") ? "active" : ""} ><i className="bx bx-wallet-alt li_icon"></i><span>واریز یا برداشت</span><i className="bx bx-left-arrow-alt li_arrow"></i></li>
                    </Link>

                    <Link to="/tickets">
                        <li className={isActive("/tickets") ? "active" : ""} ><i className="bx bx-conversation li_icon"></i><span>تیکت ها</span><i className="bx bx-left-arrow-alt li_arrow"></i></li>
                    </Link>

                    {Role === "admin" ? (
                        <Link to="/rates">
                            <li className={isActive("/rates") ? "active" : ""} ><i className="bx bx-dollar li_icon"></i><span>نرخ ها</span><i className="bx bx-left-arrow-alt li_arrow"></i></li>
                        </Link>
                    ) : (
                        <></>
                    )}




                    {/* <li><i className="bx bxs-credit-card li_icon"></i><span>تراکنش ها</span><i className="bx bx-left-arrow-alt li_arrow"></i></li> */}
                    {/* <li><i className="bx bx-support li_icon"></i><span>تیکت ها</span><i className="bx bx-left-arrow-alt li_arrow"></i></li> */}
                    {/* <li><i className='bx bxl-paypal li_icon'></i><span>درگاه های بانکی</span><i className="bx bx-left-arrow-alt li_arrow"></i></li>
                    <li><i className="bx bx-block li_icon"></i><span>مسدودی ها</span><i className="bx bx-left-arrow-alt li_arrow"></i></li> */}
                </ul>
            </div>
        </div>
    );
}

export default Sidebar;
