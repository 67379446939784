import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import Chat from '../components/chat';
import Ticket from '../components/addticket';
import StatusDiv from '../utils/status';
import { timeAgo } from '../utils/timeAgo';
import Type from '../utils/type';

// toast lib ==>
import { Toast } from 'primereact/toast';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';

import CryptoJS from 'crypto-js';

const Tickets = () => {
    const Auth_Cookie = Cookies.get('avaAuth');
    const [isLoading, setIsLoading] = useState(true);
    const [isValidCookie, setIsValidCookie] = useState(false);
    const toast = useRef(null);

    const [tickets, setTickets] = useState([]);
    const [chat, setChat] = useState("");
    const [chatData, setChatData] = useState("");
    const [showChat, setShowChat] = useState(false);
    const [addTicket, setAddticket] = useState(false);

    const [Role, setRole] = useState("user");
    const user_role = Cookies.get("theme");
    const [InnerLoader, setInnerLoader] = useState(false);
    const [ContentLoading, setContentLoading] = useState(true);
    // add order ==>
    const navigate = useNavigate();
    const [miniLoader, setMiniLoader] = useState(false);

    const decrypt = (ciphertext) => {
        const bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    useEffect(() => {
        if (decrypt(user_role) == 'user') {
            setRole('user')
        } else if (decrypt(user_role) == 'admin') {
            setRole('admin')
        } else {
            setRole('user')
        }
    }, [Role, setRole]);

    const fetchTickets = async (retries = 3) => {
        try {
            // Mocking API call to validate cookie, replace with actual API call
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/public/api/v1/tickets/ticket.php`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${Auth_Cookie}`,
                    },
                },
            );

            if (response.ok) {
                const data = await response.json();
                if (data.status == 200) {
                    setTickets(data.message);
                    setIsValidCookie(true);
                    setIsLoading(false);
                    setContentLoading(false);
                } else if (response.status === 503) {
                    retries++;
                    await new Promise(resolve => setTimeout(resolve, 1000));
                } else {
                    if (data.status == 403) {
                        Cookies.remove("avaAuth");
                        navigate("/login");
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Error",
                            detail: data.message,
                            life: 5000,
                        });
                    }
                }
            } else {
                navigate("/login");
            }
        } catch (error) {
            navigate("/login");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {

        if (!Auth_Cookie) {
            navigate('/login');
            return; // Exit early if no cookie is present
        }
        fetchTickets();
    }, [navigate]);

    const addTicketHanlde = (ticket) => {
        setAddticket(true);
        // setShowWithdraw(false);
    };

    const openChat = (ticket) => {
        setChatData(ticket["text"]);
        setShowChat(true);
        setChat(ticket)
    };

    const closeTicket = async (ticket) => {
        setMiniLoader(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/tickets/ticket.php?id=${ticket.id}`, {
            method: 'PUT',
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${Auth_Cookie}`,
            },
            redirect: "follow"
        });

        const data = await response.json();
        if (data.status == 200) {
            toast.current.show({
                severity: "success",
                summary: "موفق",
                detail: data.message,
                life: 5000,
            });
            setMiniLoader(false);
            fetchTickets();
        } else {
            toast.current.show({
                severity: "error",
                summary: "خطا",
                detail: data.message,
                life: 5000,
            });
            setMiniLoader(false);
        }
    };

    return (
        <>
            {InnerLoader != "" ? (
                <div className="loading_wrapper">
                    <span className="loader"></span>
                </div>
            ) : (
                <></>
            )}
            <Toast ref={toast} />
            <div className="lg:flex" >
                <Sidebar />
                <div className="mainbar" >
                    <Navbar />
                    <div className="main">

                        <div className="single_page_title">

                            <h1>تیکت ها</h1>
                            <h2>tickets </h2>

                            <div className="mr-auto"><i className="bx bx-conversation li_icon"></i></div>
                        </div>

                        {addTicket ? (<Ticket />) : (<></>)}


                        <button type='button' className='button mt-5 ml-4' onClick={() => addTicketHanlde()} >
                            <i className="pi pi-plus-circle ml-2" style={{ fontSize: '1rem' }}></i>
                            ثبت تیکت جدید
                        </button>

                        {ContentLoading == true ? (
                            <div className="lg:grid lg:grid-cols-1 gap-5 mt-8 card p-0">
                                <span className="content_loader"></span>
                            </div>
                        ) : (
                            <>
                                <div >
                                    <div className="lg:flex gap-5">
                                        <div className={`trns mt-6 ${showChat ? 'lg:w-2/3' : 'w-full'}`} >
                                            <div className='card p-1'>
                                                <div className="table_scroll">
                                                    {tickets.length > 0 ? (
                                                        <>
                                                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                                    <tr>
                                                                        <th scope="col" className="px-6 py-3">
                                                                            عنوان تیکت
                                                                        </th>
                                                                        <th scope="col" className="px-6 py-3">
                                                                            تاریخ ثبت
                                                                        </th>
                                                                        <th scope="col" className="px-6 py-3">
                                                                            وضعیت
                                                                        </th>
                                                                        {Role == "admin" ? (
                                                                            <th scope="col" className="px-6 py-3">
                                                                                ارسال کننده
                                                                            </th>
                                                                        ) : (
                                                                            <></>
                                                                        )}

                                                                        <th scope="col" className="px-6 py-3">
                                                                            عملیات
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {tickets.map((ticket, index) => (
                                                                        <tr
                                                                            key={index}
                                                                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 order_success"
                                                                        >
                                                                            <td className="px-6 py-4">
                                                                                {ticket.Title}
                                                                            </td>
                                                                            <td className="px-6 py-3">
                                                                                {timeAgo(ticket.Date)}
                                                                            </td>
                                                                            <td className="px-6 py-3">
                                                                                <StatusDiv statusCode={ticket.Status} />
                                                                            </td>
                                                                            {Role == "admin" ? (
                                                                                <td className="px-6 py-3">
                                                                                    {ticket.Email}
                                                                                </td>
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                            <td className="px-6 py-3 flex">
                                                                                <button className="button mini_button" onClick={() => openChat(ticket)} >
                                                                                    مشاهده
                                                                                </button>
                                                                                {Role == "admin" && ticket.Status == 201 ? (
                                                                                    <>
                                                                                        {miniLoader ? (
                                                                                            <div className="mini_loader pt-0 mr-4">
                                                                                                <div className="loader_small"></div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <button className="button mini_button mr-3 close_btn " onClick={() => closeTicket(ticket)} >
                                                                                                بستن تیکت
                                                                                            </button>
                                                                                        )}

                                                                                    </>
                                                                                ) : (
                                                                                    <></>
                                                                                )}

                                                                            </td>



                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </>

                                                    ) : (
                                                        <div className="hint_box flex items-center">
                                                            <i className="pi pi-bell" style={{ fontSize: '1.5rem' }}></i>
                                                            <div className="pr-5" >
                                                                <b className="mb-2 block" >
                                                                    شما هیچ تبدیلی انجام نداده اید
                                                                </b>
                                                                <span>
                                                                    برای تبدیل ارز از صفحه داشبورد اقدام کنید
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </div>

                                        {showChat ? (
                                            <Chat chatData={chat} />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}


                    </div>
                </div>
            </div>
        </>
    );

};

export default Tickets;
