
import React, { useState, useRef } from "react";
import Cookies from 'js-cookie';

// toast lib ==>
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";


const Chat = ({ chatData }) => {

    const Auth_Cookie = Cookies.get('avaAuth');
    // chat vars =>
    const [title, setTitle] = useState([]);
    const [text, setText] = useState([]);
    const [miniLoader, setMiniLoader] = useState(false);

    const toast = useRef(null);

    const addTicket = async (e) => {
        e.preventDefault();
        setMiniLoader(true)
        try {

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/tickets/ticket.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${Auth_Cookie}`,
                }, body: new URLSearchParams({
                    "Text": text,
                    "Title": title
                }),
                redirect: "follow"
            });

            const data = await response.json();
            if (data.status === 200) {
                toast.current.show({
                    severity: "success",
                    summary: "موفق",
                    detail: data.message,
                    life: 5000,
                });
                window.location.reload();
                // setMiniLoader(false);
            } else {
                toast.current.show({
                    severity: "error",
                    summary: "خطا",
                    detail: data.message,
                    life: 5000,
                });
                setMiniLoader(false);
            }

        } catch (error) {
            if (error instanceof Error) {
                toast.current.show({
                    severity: "error",
                    summary: "خطا",
                    detail: error.message,
                    life: 5000,
                });
                setMiniLoader(false);
            } else {
                console.log('An unexpected error occurred');
            }
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <div className="card p-6 mt-6 w-full mb-5 w-full">
                <h1 className="section_title">افزودن تیکت جدید</h1>

                <form onSubmit={addTicket} className="mt-6" >
                    <div className="lg:flex">
                        <div className="mb-5 lg:w-1/3 lg:pl-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">عنوان تیکت</label>
                            <input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="عنوان تیکت" required type="text" className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom" />

                            {miniLoader ? (
                                <div className="mini_loader pt-7">
                                    <div className="loader_small"></div>
                                </div>
                            ) : (
                                <button className="button w-full mt-4" type="submit" >ثبت و ارسال</button>
                            )}



                        </div>
                        <div className="mb-5 lg:w-2/3 lg:pr-3">
                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">متن تیکت</label>
                            <textarea
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                required rows="5" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="متن تیکت خود را بنویسید ..."></textarea>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Chat;
