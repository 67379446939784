import React from 'react';

const StatusDiv = ({ statusCode }) => {
  const getStatusClass = (code) => {
    switch (code) {
      case 200:
      case 201:
      case 204:
        return 'status-success';
      case 400:
      case 401:
      case 403:
      case 404:
      case 406:
        return 'status-error';
      case 500:
      case 502:
      case 0:
      case 503:
        return 'status-warning';
      default:
        return 'status-info';
    }
  };

  const getPersianText = (code) => {
    switch (code) {
      case 0:
        return 'انجام نشده';
      case 1:
        return 'انجام شده';
      case 100:
        return 'درانتظار پرداخت';
      case 102:
        return 'درانتظار تایید';            
      case 200:
        return 'تکمیل شده';
      case 201:
        return 'ایجاد شده';
      case 204:
        return 'بدون محتوا';
      case 400:
        return 'درخواست نادرست';
      case 401:
        return 'رد شده';
      case 403:
        return 'ممنوع';
      case 404:
        return 'یافت نشد';
      case 406:
        return 'بسته شده';
      case 500:
        return 'خطای سرور داخلی';
      case 502:
        return 'دروازه بد';
      case 503:
        return 'سرویس در دسترس نیست';
      default:
        return 'وضعیت نامشخص';
    }
  };

  return (
    <div className={getStatusClass(statusCode)}>
      {getPersianText(statusCode)}
    </div>
  );
};

export default StatusDiv;
