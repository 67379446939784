const apiRequest = async (endpoint, authCookie) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authCookie}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            return {
                success: true,
                data
            };
        } else {
            const errorData = await response.json();
            return {
                success: false,
                error: errorData.error || 'An error occurred'
            };
        }
    } catch (error) {
        return {
            success: false,
            error: 'Network error'
        };
    }
};

export default apiRequest; // Default export