
import React, { useState, useRef, useEffect } from "react";
// import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
// toast lib ==>
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";
import { QRCodeSVG } from 'qrcode.react';


// import wallet from '../images/TGtRJcjeyT7tCqRXnmEhjk62d9w7E2SpTq.svg'
import trc20 from '../images/tether-usdt-trc20.svg'
import Countdown from 'react-countdown';



// Renderer callback with condition
const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <span className="error_box block items-center my-4 mini_hint mb-0">مدت زمان واریز به این کیف پول به اتمام رسیده است. لطفاً از واریز به این کیف پول خودداری کنید</span>;
    } else {
        // Render a countdown
        return <span className="hint_box block items-center my-4 mini_hint mb-0">شما {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')} دقیقه دیگر برای واریز به این کیف پول فرصت دارید.</span>;
    }
};



// const ManualPayment = ({paymentData}) => {
const ManualPayment = () => {

    const Auth_Cookie = Cookies.get('avaAuth');
    // const navigate = useNavigate();

    // const { user, loading } = useUser();

    // uploader =>
    // const [previewUrl, setPreviewUrl] = useState(null);
    // const [fileName, setFileName] = useState("");
    // const [image, setImage] = useState(null);
    // const [InnerLoader, setInnerLoader] = useState(false);
    const [trackingCode, setTrackingCode] = useState("");
    // const [gateway, setGateway] = useState("Online");
    const toast = useRef(null);
    const [miniLoader, setMiniLoader] = useState(false);

    const [amount, setAmount] = useState("");
    // const [userWallet, setUserWallet] = useState("");
    const [setValues, setSetValues] = useState(true);
    const [error, setError] = useState(false);
    // const [success, setSuccess] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [wallet, setWallet] = useState("");
    const [status, setStatus] = useState(null);
    const [expiration, setExpiration] = useState(null);



    const WalletDisplay = ({ wallet }) => {
        const firstThree = wallet.slice(0, 3); // First 3 characters
        const lastFour = wallet.slice(-6); // Last 4 characters
        const middlePart = wallet.slice(3, -6); // Middle part of the wallet

        return (
            <p>
                <span style={{ color: 'red' }}>{firstThree}</span>
                {middlePart}
                <span style={{ color: 'red' }}>{lastFour}</span>
            </p>
        );
    };


    const getWallet = async (amount) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${Auth_Cookie}`,
            },
            body: new URLSearchParams({ "amount": amount }),
            redirect: "follow",
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/gateway/wallet.php`, requestOptions);

            // Check if the response is ok (status in the range 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            // Handle different response statuses
            if (data.status === 200) {
                return {
                    address: data.address,
                    transaction_id: data.transaction_id
                };
            } else if (data.status === 503) {
                return 503; // Return 503 if the service is busy
            } else {
                return false; // Return false for other statuses
            }

        } catch (error) {
            console.error('Error fetching wallet:', error); // Log the error for debugging
            return false; // Return false on error
        }
    };




    const switchToUSDTGateway = async (e) => {
        e.preventDefault();
        setMiniLoader(true);

        try {
            const generateWallet = await getWallet(amount);

            if (generateWallet === 503) {
                setError(true);
                setErrorText('شبکه شلوغ است، لطفاً چند دقیقه دیگر تلاش کنید.');
            } else if (!generateWallet) {
                setError(true);
                setErrorText('مشکلی پیش آمده است، لطفاً چند دقیقه دیگر تلاش کنید.');
            } else {
                setWallet(generateWallet.address);
                setTrackingCode(generateWallet.transaction_id);
                setError(false);
                setSetValues(false);
            }
        } catch (error) {
            console.error('Error fetching wallet:', error);
            setError(true);
            setErrorText('مشکلی پیش آمده است، لطفاً بعداً تلاش کنید.');
        } finally {
            setMiniLoader(false);
        }
    };

    const fetchTransactionStatus = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${Auth_Cookie}`,
            },
            redirect: "follow",
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/gateway/transactions.php?id=${trackingCode}`, requestOptions);

            // Check if the response is ok (status in the range 200-299)
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            // Handle different response statuses
            if (data.status === 200) {
                setExpiration(data.transactions[0].expiration_time);
                setStatus(data.transactions[0].status);
            }
        } catch (error) {
            console.error('Error fetching transaction status:', error);
            return false; // Return false on error
        }
    };


    // Set up polling effect and trigger fetchTransactionStatus immediately when trackingCode changes
    useEffect(() => {
        if (trackingCode) {
            fetchTransactionStatus();  // Immediate fetch on trackingCode change

            const intervalId = setInterval(() => {
                fetchTransactionStatus();  // Poll every 60 seconds
            }, 60000);

            return () => clearInterval(intervalId);  // Cleanup
        }
    }, [trackingCode, fetchTransactionStatus]);


    return (
        <>
            <Toast ref={toast} />

            {setValues ? (
                <form className="mb-5" onSubmit={switchToUSDTGateway}>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        مقدار تتر
                    </label>
                    <input
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="حداقل 10 تتر" required type="number" step="0.1" min="10" dir="rtl"
                        className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom number"
                    />

                    <span className="hint_box block items-center my-4 mini_hint mb-0">
                        مقدار واریزی دلخواه خود را دقیق وارد کنید. تراکنش‌های با عدد دیگر به نام شما ثبت نخواهند شد.
                    </span>
                    <div className="hint_box block items-center my-4 mini_hint mb-0">
                        <span className="block">
                            آواپی هیچ مسئولیتی در قبال اشتباهات کاربران هنگام انتقال رمزارز ندارد. لطفا مراحل را با دقت تکمیل کنید.
                        </span>
                    </div>

                    {miniLoader ? (
                        <div className="mini_loader pt-7">
                            <div className="loader_small"></div>
                        </div>
                    ) : (
                        <>
                            {error ? (
                                <div className="error_box">{errorText}</div>
                            ) : (
                                <></>
                            )}
                            <button className="button w-full mt-6" type="submit">ادامه</button>
                        </>
                    )}
                </form>
            ) : (
                <>
                    {status === 'expired' ? (
                        <div className="error_box block items-center mt-4 mb-0 mini_hint">
                            <span className="block text-red-500">
                                مهلت زمان شما برای واریز به این کیف پول به اتمام رسیده است، لطفاً از واریز به آدرس داده شده اجتناب و مراحل را از ابتدا دنبال کنید.
                            </span>
                        </div>
                    ) : status === 'completed' ? (
                        <div className="success_box block items-center mt-4 mb-0 mini_hint">
                            <span className="block text-green-500">
                                تبریک! 🎉
                                واریزی شما با موفقیت انجام و ثبت شد.
                            </span>
                        </div>
                    ) : (
                        <>
                            <div className="hint_box block items-center mt-0 mb-4 mini_hint">
                                <span className="block">
                                    مقدار وارد شده باید با مقدار واریزی برابر باشد در غیراینصورت تراکنش شما تایید نخواهد شد
                                </span>
                            </div>
                            {expiration ? (
                                <Countdown date={expiration * 1000} renderer={renderer} />
                            ) : (
                                <></>
                            )}

                            {wallet && (
                                <>
                                    <div className="flex items-center justify-center" dir="ltr">
                                        <img className="mr-2" src={trc20} width={40} height={40} alt="USDT - TRC20" />
                                        <span className="number">
                                            <b className="amount_no">{amount}</b> USDT
                                            <small className="label ml-2">TRON</small>
                                        </span>
                                    </div>
                                    <QRCodeSVG value={wallet} size="200" style={{ margin: '0 auto' }} />
                                    <div className="text-center">
                                        <div className="wallet_address number">
                                            <WalletDisplay wallet={wallet} />
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}

                    {miniLoader ? (
                        <div className="mini_loader py-7">
                            <div className="loader_small"></div>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );



}

export default ManualPayment;
