// import React, { useEffect, useState, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Cookies from 'js-cookie';
// import Sidebar from "../components/sidebar";
// import Navbar from "../components/navbar";
// import ManualPayment from '../components/manualpayment';
// import { Toast } from 'primereact/toast';
// import "primereact/resources/themes/lara-light-cyan/theme.css";
// import 'primereact/resources/primereact.min.css';
// import CryptoJS from 'crypto-js';

// // icons
// import USDT from '../images/USDT.svg';
// import EUR from '../images/EUR.svg';
// import USD from '../images/USD.svg';
// import GBP from '../images/GBP.svg';

// const CurrencyRateInput = ({ label, rate, onRateChange }) => (
//   <div className="mb-5">
//     <label
//       htmlFor="rate"
//       className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
//     >
//       {label}
//       <small className='mr-2'><b>TOMAN</b></small>
//     </label>
//     <input
//       value={rate}
//       onChange={(e) => onRateChange(e.target.value.replace(/,/g, ''))}
//       type="tel"
//       dir='rtl'
//       placeholder="567000"
//       className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom number"
//       required
//     />
//   </div>
// );

// const Rates = () => {
//   const Auth_Cookie = Cookies.get('avaAuth');
//   const [isLoading, setIsLoading] = useState(true);
//   const [isValidCookie, setIsValidCookie] = useState(false);
//   const toast = useRef(null);
//   const [payment, setPayment] = useState([]);
//   const [showPayment, setShowPayment] = useState(false);
//   const [Role, setRole] = useState("user");
//   const user_role = Cookies.get("theme");
//   const [InnerLoader, setInnerLoader] = useState(false);
//   const [ContentLoading, setContentLoading] = useState(true);
//   const navigate = useNavigate();

//   const decrypt = (ciphertext) => {
//     const bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_KEY);
//     return bytes.toString(CryptoJS.enc.Utf8);
//   };

//   useEffect(() => {
//     if (decrypt(user_role) === 'user') {
//       setRole('user');
//       navigate('/login');
//       return;
//     } else if (decrypt(user_role) === 'admin') {
//       setRole('admin');
//     } else {
//       setRole('user');
//     }
//   }, [Role, setRole]);

//   useEffect(() => {
//     if (!Auth_Cookie) {
//       navigate('/login');
//       return;
//     }

//     const fetchRates = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_BASE_URL}/public/api/v1/rates/rate.php`,
//           {
//             method: "GET",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${Auth_Cookie}`,
//             },
//           },
//         );

//         if (response.ok) {
//           const data = await response.json();
//           if (data.status === 200) {
//             setIsValidCookie(true);
//             setContentLoading(false);

//             const ratesData = data.message.reduce((acc, item) => {
//               acc[item.Currency] = { "SellRate": item.SellRate, "BuyRate": item.BuyRate };
//               return acc;
//             }, {});

//             setRate(ratesData);
//           } else {
//             navigate("/login");
//           }
//         } else {
//           navigate("/login");
//         }
//       } catch (error) {
//         navigate("/login");
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchRates();
//   }, [navigate]);

//   const [rates, setRate] = useState({
//     USD: { BuyRate: '', SellRate: '' },
//     USDT: { BuyRate: '', SellRate: '' },
//     EUR: { BuyRate: '', SellRate: '' },
//     GBP: { BuyRate: '', SellRate: '' }
//   });

//   const handleRateChange = (currency, type, value) => {
//     setRate(prevRates => ({
//       ...prevRates,
//       [currency]: {
//         ...prevRates[currency],
//         [type]: value.replace(/,/g, '')
//       }
//     }));
//   };

//   const handleSubmit = async (e, currency) => {
//     e.preventDefault();
//     try {
//       setInnerLoader(true);
//       const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/rates/rate.php?` +
//         `Currency=${currency}&` +
//         `Buyrate=${rates[currency].BuyRate}&` +
//         `Sellrate=${rates[currency].SellRate}`,
//         {
//           method: "PUT",
//           headers: {
//             'Authorization': `Bearer ${Auth_Cookie}`,
//           },
//           redirect: "follow",
//         }
//       );

//       const data = await response.json();
//       if (data.status === 200) {
//         window.location.reload();
//       } else {
//         alert(data.message);
//         setInnerLoader(false);
//       }
//     } catch (error) {
//       if (error instanceof Error) {
//         console.log(error.message);
//       } else {
//         console.log('An unexpected error occurred');
//       }
//     }
//   };

//   return (
//     <>
//       {InnerLoader && (
//         <div className="loading_wrapper">
//           <span className="loader"></span>
//         </div>
//       )}
//       <Toast ref={toast} />
//       <div className="lg:flex">
//         <Sidebar />
//         <div className="mainbar">
//           <Navbar />
//           <div className="main">
//             {showPayment && <ManualPayment paymentData={payment} />}
//             <div className="single_page_title">
//               <h1>نرخ ها</h1>
//               <h2>rates</h2>
//               <div className="mr-auto"><i className="bx bx-dollar li_icon"></i></div>
//             </div>

//             {ContentLoading ? (
//               <div className="lg:grid lg:grid-cols-4 gap-5 mt-8">
//                 <span className="content_loader rate_loader card p-0"></span>
//                 <span className="content_loader rate_loader card p-0"></span>
//                 <span className="content_loader rate_loader card p-0"></span>
//                 <span className="content_loader rate_loader card p-0"></span>
//               </div>
//             ) : (
//               <div className="lg:grid lg:grid-cols-4 gap-5 mt-8">
//                 {Object.keys(rates).map(currency => (
//                   <div key={currency} className="card p-7">
//                     <div className="card_heading_meta flex items-center mb-7">
//                       <div >
//                         {currency === 'USD' && <img src={USD} width={35} height={35} alt="USD Icon" />}
//                         {currency === 'USDT' && <img src={USDT} width={35} height={35} alt="USDT Icon" />}
//                         {currency === 'EUR' && <img src={EUR} width={35} height={35} alt="EUR Icon" />}
//                         {currency === 'GBP' && <img src={GBP} width={35} height={35} alt="GBP Icon" />}
//                       </div>
//                       <div className="rate_title">
//                         نرخ های {currency}
//                       </div>
//                     </div>
//                     <form className='pt-5' onSubmit={(e) => handleSubmit(e, currency)}>
//                       <CurrencyRateInput
//                         label={'نرخ خرید از ما'}
//                         rate={parseFloat(rates[currency].SellRate).toLocaleString()}
//                         onRateChange={(value) => handleRateChange(currency, 'SellRate', value)}
//                       />
//                       <CurrencyRateInput
//                         label={'نرخ فروش به ما'}
//                         rate={parseFloat(rates[currency].BuyRate).toLocaleString()}
//                         onRateChange={(value) => handleRateChange(currency, 'BuyRate', value)}
//                       />
//                       <button type='submit' className='button w-full'>بروزرسانی {currency}</button>
//                     </form>
//                   </div>
//                 ))}
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Rates;


import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import ManualPayment from '../components/manualpayment';
import { Toast } from 'primereact/toast';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primereact/resources/primereact.min.css';
import CryptoJS from 'crypto-js';

// icons
import USDT from '../images/USDT.svg';
import EUR from '../images/EUR.svg';
import USD from '../images/USD.svg';
import GBP from '../images/GBP.svg';

const CurrencyRateInput = ({ label, rate, onRateChange }) => (
  <div className="mb-5">
    <label
      htmlFor="rate"
      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
    >
      {label}
      <small className='mr-2'><b>TOMAN</b></small>
    </label>
    <input
      value={rate}
      onChange={(e) => onRateChange(e.target.value.replace(/,/g, ''))}
      type="tel"
      dir='rtl'
      placeholder="567000"
      className="flex items-center shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light input_custom number"
      required
    />
  </div>
);

const Rates = () => {
  const Auth_Cookie = Cookies.get('avaAuth');
  const [isLoading, setIsLoading] = useState(true);
  const [isValidCookie, setIsValidCookie] = useState(false);
  const toast = useRef(null);
  const [payment, setPayment] = useState([]);
  const [showPayment, setShowPayment] = useState(false);
  const [Role, setRole] = useState("user");
  const user_role = Cookies.get("theme");
  const [InnerLoader, setInnerLoader] = useState(false);
  const [ContentLoading, setContentLoading] = useState(true);
  const navigate = useNavigate();

  const decrypt = (ciphertext) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    if (decrypt(user_role) === 'user') {
      setRole('user');
      navigate('/login');
      return;
    } else if (decrypt(user_role) === 'admin') {
      setRole('admin');
    } else {
      setRole('user');
    }
  }, [Role, setRole]);

  useEffect(() => {
    if (!Auth_Cookie) {
      navigate('/login');
      return;
    }

    const fetchRates = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/public/api/v1/rates/rate.php`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Auth_Cookie}`,
            },
          },
        );

        if (response.ok) {
          const data = await response.json();
          if (data.status === 200) {
            setIsValidCookie(true);
            setContentLoading(false);

            const ratesData = data.message.reduce((acc, item) => {
              acc[item.Currency] = { "SellRate": item.SellRate, "BuyRate": item.BuyRate };
              return acc;
            }, {});

            setRate(ratesData);
          } else {
            navigate("/login");
          }
        } else {
          navigate("/login");
        }
      } catch (error) {
        navigate("/login");
      } finally {
        setIsLoading(false);
      }
    };

    fetchRates();
  }, [navigate]);

  const [rates, setRate] = useState({
    USD: { BuyRate: '', SellRate: '' },
    USDT: { BuyRate: '', SellRate: '' },
    EUR: { BuyRate: '', SellRate: '' },
    GBP: { BuyRate: '', SellRate: '' }
  });

  const handleRateChange = (currency, type, value) => {
    setRate(prevRates => ({
      ...prevRates,
      [currency]: {
        ...prevRates[currency],
        [type]: value.replace(/,/g, '')
      }
    }));
  };

  const handleSubmit = async (e, currency) => {
    e.preventDefault();
    try {
      setInnerLoader(true);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/rates/rate.php?` +
        `Currency=${currency}&` +
        `Buyrate=${rates[currency].BuyRate}&` +
        `Sellrate=${rates[currency].SellRate}`,
        {
          method: "PUT",
          headers: {
            'Authorization': `Bearer ${Auth_Cookie}`,
          },
          redirect: "follow",
        }
      );

      const data = await response.json();
      if (data.status === 200) {
        window.location.reload();
      } else {
        alert(data.message);
        setInnerLoader(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
      } else {
        console.log('An unexpected error occurred');
      }
    }
  };

  return (
    <>
      {InnerLoader && (
        <div className="loading_wrapper">
          <span className="loader"></span>
        </div>
      )}
      <Toast ref={toast} />
      <div className="lg:flex">
        <Sidebar />
        <div className="mainbar">
          <Navbar />
          <div className="main">
            {showPayment && <ManualPayment paymentData={payment} />}
            <div className="single_page_title">
              <h1>نرخ ها</h1>
              <h2>rates</h2>
              <div className="mr-auto"><i className="bx bx-dollar li_icon"></i></div>
            </div>

            {ContentLoading ? (
              <div className="lg:grid lg:grid-cols-4 gap-5 mt-8">
                <span className="content_loader rate_loader card p-0"></span>
                <span className="content_loader rate_loader card p-0"></span>
                <span className="content_loader rate_loader card p-0"></span>
                <span className="content_loader rate_loader card p-0"></span>
              </div>
            ) : (
              <div className="lg:grid lg:grid-cols-4 gap-5 mt-8">
                {Object.keys(rates).map(currency => (
                  <div key={currency} className="card p-7">
                    <div className="card_heading_meta flex items-center mb-7">
                      <div >
                        {currency === 'USD' && <img src={USD} width={35} height={35} alt="USD Icon" />}
                        {currency === 'USDT' && <img src={USDT} width={35} height={35} alt="USDT Icon" />}
                        {currency === 'EUR' && <img src={EUR} width={35} height={35} alt="EUR Icon" />}
                        {currency === 'GBP' && <img src={GBP} width={35} height={35} alt="GBP Icon" />}
                      </div>
                      <div className="rate_title">
                        نرخ های {currency}
                      </div>
                    </div>
                    <form className='pt-5' onSubmit={(e) => handleSubmit(e, currency)}>
                      <CurrencyRateInput
                        label={'نرخ خرید از ما'}
                        rate={rates[currency].SellRate ? parseFloat(rates[currency].SellRate).toLocaleString() : ''}
                        onRateChange={(value) => handleRateChange(currency, 'SellRate', value)}
                      />
                      <CurrencyRateInput
                        label={'نرخ فروش به ما'}
                        rate={rates[currency].BuyRate ? parseFloat(rates[currency].BuyRate).toLocaleString() : ''}
                        onRateChange={(value) => handleRateChange(currency, 'BuyRate', value)}
                      />
                      <button type='submit' className='button w-full'>بروزرسانی {currency}</button>
                    </form>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Rates;

