import React, { useEffect, useState, useRef } from "react";
import Sidebar from "../sidebar";
import Navbar from "../navbar";
import Cookies from 'js-cookie';

import KycImageCard from "./kycImageCard";
import PersonalInfoCard from "./personalInfoCard";

// toast lib ==>
import { Toast } from "primereact/toast";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.min.css";

// Jalali date picker ==>
// import { DatePicker } from "@react-shamsi/datepicker";
// import "@react-shamsi/calendar/dist/styles.css";
// import "@react-shamsi/datepicker/dist/styles.css";


const User = ({ userData }) => {
    //   const [isLoading, setIsLoading] = useState(true);
    // const [isValidCookie, setIsValidCookie] = useState(true);
    // const [user, setUser] = useState([]);
    const toast = useRef(null);
    // const [InnerLoader, setInnerLoader] = useState(false);
    const [ContentLoading, setContentLoading] = useState(true);

    useEffect(() => {
        if (userData[0] != null) {
            setContentLoading(false)
        }
    }, [userData]);

    const isIdentified = () => userData[0]['Identify'];


    return (
        <>
            <Toast ref={toast} />
            <div className="lg:flex">
                <Sidebar />
                <div className="mainbar">
                    <Navbar />
                    <div className="main">
                        <div className="single_page_title">
                            <h1>اطلاعات و احراز هویت</h1>
                            <h2>identify</h2>

                            <div className="mr-auto">
                                <i className="bx bx bx-user li_icon"></i>
                            </div>
                        </div>


                        {ContentLoading === true ? (
                            <>
                                <span className="content_loader mini mt-5"></span>
                                <div className="lg:grid lg:grid-cols-2 gap-5 mt-6">
                                    <span className="content_loader card p-0"></span>
                                    <span className="content_loader card p-0"></span>
                                </div>
                            </>
                        ) : (
                            <>
                                {isIdentified() ? (
                                    <div className="sent_image mt-5">احراز هویت شما تایید شده است</div>
                                ) : (
                                    <div className="hint_box flex items-center mt-5">
                                        <i className="pi pi-id-card" style={{ fontSize: '1.5rem' }}></i>
                                        <div className="pr-5">
                                            <b className="mb-2 block">
                                                {userData[0]['Message'] == null ? (
                                                    <>احراز هویت شما در دست بررسی می باشد.</>
                                                ) : (
                                                    <>{userData[0]['Message']}</>
                                                )}
                                            </b>
                                            <span>لطفا از تکمیل بودن اطلاعات خود اطمینان حاصل فرمایید.</span>
                                        </div>
                                    </div>
                                )}

                                <div className={`lg:grid gap-5 ${(isIdentified()) ? 'lg:grid-cols-1' : 'lg:grid-cols-2'}`}>
                                    {!isIdentified() &&
                                        <KycImageCard userData={userData} />
                                    }

                                    <PersonalInfoCard userData={userData} isIdentified={isIdentified()} />
                                </div>
                            </>
                        )}





                    </div>
                </div>
            </div>
        </>
    );
};

export default User;
