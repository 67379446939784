import React, {
    useEffect,
    useState
} from 'react';
import {
    useNavigate
} from 'react-router-dom';
import Cookies from 'js-cookie';
import User from "../components/indentify/user";
import Admin from "../components/indentify/admin";

// import { useSelector, useDispatch } from 'react-redux';
// import { setUser, clearUser } from '../features/userSlice';


const Identify = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isValidCookie, setIsValidCookie] = useState(false);
    const [user, setUser] = useState([]);
    const [role, setRole] = useState([]);

    // const dispatch = useDispatch();
    // const user = useSelector((state) => state.user.user);


    useEffect(() => {
        const Auth_Cookie = Cookies.get('avaAuth');

        if (!Auth_Cookie) {
            navigate('/login');
            return; // Exit early if no cookie is present
        }

        const fetchUsers = async (retries = 3) => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/public/api/v1/users/user.php`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${Auth_Cookie}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.status === 200) {
                        setIsValidCookie(true);
                        setUser(data.message);
                        // dispatch(setUser(data.message));
                        setRole(data.role);
                    } else {
                        navigate('/login');
                    }
                } else if (response.status === 503) {
                    retries++;
                    await new Promise(resolve => setTimeout(resolve, 1000));
                } else {
                    navigate('/login');
                }
            } catch (error) {
                navigate('/login');
            } finally {
                setIsLoading(false); // Always set loading to false when done
            }
        };

        fetchUsers();
    }, [navigate]);



    if (parseInt(role) === 1) {
        return <Admin />;
    } else {
        return <User userData={user} />;
    }

};

export default Identify;